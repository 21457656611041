import { Table } from 'react-bootstrap';
import '../../../styles/ModalContent.css';
import DatePicker from 'react-date-picker';
import { useState, useEffect } from 'react';
import { getSingleStat } from '../../../http/statApi.js';
// import 'react-date-picker/dist/DatePicker.css';
import '../../../styles/Calendar.css'
import { getGraphs } from '../../../http/complexesApi.js';
import { PhotoProvider, PhotoView } from 'react-photo-view';


const ComplexStatistic = props => {
    const [interval, setInterval] = useState([new Date(new Date().setHours(0,0,0,0)), new Date()])
    const [fixations, setFixations] = useState(0)
    const [violations, setViolations] = useState(0)
    const [violationsWrongDir, setViolationsWrongDir] = useState(0)
    const [violationsStopLine, setViolationsStopLine] = useState(0)
    const [violationsRedLight, setViolationsRedLight] = useState(0)
    const [violationsLeftTurn, setViolationsLeftTurn] = useState(0)
    const [graph, setGraph] = useState(null)



    useEffect(() => {
        console.log(interval)
        if (interval[0].getTime() > interval[1].getTime()) {
            setInterval([interval[1], interval[1]])
        } else {
            getSingleStat(interval[0], interval[1], props.complex.host).then(data => {
                if (data) {
                setFixations(data.totalFixations)
                setViolations(data.totalViolations)
                setViolationsWrongDir(data.totalViolationsWrongDir)
                setViolationsStopLine(data.totalViolationsStopLine)
                setViolationsRedLight(data.totalViolationsRedLight)
                setViolationsLeftTurn(data.totalViolationsLeftTurn)
                }
            }).catch(err => {
                console.log(err)
            })
            
            let sDate = new Date(interval[0].getTime() + 3*60*60*1000)
            let eDate = new Date(interval[1].getTime() + 3*60*60*1000)
            if (sDate.toDateString() === eDate.toDateString()) {
                eDate = new Date(eDate.getTime() + 24*60*60*1000)
            }

            if (interval[1].toDateString() === new Date().toDateString()) {
                eDate = new Date(new Date().getTime() + 3*60*60*1000)
            }
    
            getGraphs(props.complex.hostid, 'Нарушения',
                sDate.toISOString().replace('T', ' ').slice(0, -5),
                eDate.toISOString().replace('T', ' ').slice(0, -5)).then(res => {
                    if (res) {
                        setGraph(res.graphs[0])
                    }

                }).catch(err => {
                    console.log(err)
                })
        }
        
    }, [interval])

    useEffect(() => {
        setInterval([new Date(new Date().setHours(0, 0, 0, 0)), new Date()])
    }, [])

    return (
        <div>
            <div style={
                {
                    display: 'flex',
                    justifyContent: "center",
                    flexDirection: 'row',
                    marginTop: "10px",
                    flexWrap: 'wrap'
                }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', border: '1px  solid #dee2e6', backgroundColor: '#f8f9fa', borderRadius: '5px', margin: '2px 30px' }}>
                        <span style={{ alignContent: 'center', margin: '2px 20px'}}>Начало периода</span>
                        <DatePicker key={1} onChange={val => setInterval([val, interval[1]])} value={interval[0]} clearIcon={false}/>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', border: '1px  solid #dee2e6', backgroundColor: '#f8f9fa', borderRadius: '5px', margin: '2px 30px' }}>
                        <span style={{ alignContent: 'center', margin: '2px 20px'}}>Конец периода</span>
                        <DatePicker key={2} onChange={val => setInterval([interval[0], val])} value={interval[1]} clearIcon={false}/>

                </div>




            </div>
            {/* <hr />   */}

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '10px 30px' }}>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent'}} onClick={() => {
                            setInterval([new Date(new Date().setHours(0,0,0,0)), new Date()])
                        }}>Сегодня</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent'}} onClick={() => {
                            const tmp = new Date(new Date(new Date().getTime() - 1*24*60*60*1000))
                            // setInterval([new Date(new Date(new Date().getTime() - 1*24*60*60*1000).setHours(0,0,0,0)), new Date(new Date().setHours(23,59,59,0))])
                            setInterval([ new Date(tmp.setHours(0,0,0,0)), new Date(tmp.setHours(23,59,59,0))])
                        }}>Вчера</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent'}} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 3*24*60*60*1000)), new Date()])
                        }}>Последние 3 дня</button>    
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent'}} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 7*24*60*60*1000)), new Date()])
                        }}>Последние 7 дней</button> 
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent'}} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 30*24*60*60*1000)), new Date()])
                        }}>Последние 30 дней</button>
                    {/* </InputGroup> */}
                </div>

            {/* <hr /> */}
            <div >
                <Table bordered striped>
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th rowSpan={2} style={{ verticalAlign: 'middle' }}>Проездов</th>
                            <th colSpan={5} >Нарушений</th>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                            {/* <th></th> */}
                            <th >Всего</th>
                            <th>Проезд на красный</th>
                            <th>Стоп линия</th>
                            <th>Выезд на встречную полосу</th>
                            <th>Поворот не с того ряда</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr style={{ textAlign: 'center' }}>
                            <td>{fixations}</td>
                            <td>{violations}</td>
                            <td>{violationsRedLight}</td>
                            <td>{violationsStopLine}</td>
                            <td>{violationsWrongDir}</td>
                            <td>{violationsLeftTurn}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            { graph ? 
            <div key={graph[0]}>  
                <hr />         
                <PhotoProvider>
                    <PhotoView key={graph[0] + "ph"} src={`data:image/jpeg;base64,${graph[1]}`}>
                        <img key={graph[0] + "img"} src={`data:image/jpeg;base64,${graph[1]}`} width={'100%'} alt='' />
                    </PhotoView>
                </PhotoProvider>
            </div> : null}
            
        </div>
    )


}

export default ComplexStatistic