import { MapContainer } from "react-leaflet";
import ModalInfo from "../components/modals/ModalInfo";
import ModalSearch from "../components/modals/ModalSearch";
import ModalStat from "../components/modals/ModalStat";
import ModalUsers from "../components/modals/ModalUsers";
import ControlLayer from "../components/layers/ControlLayer";
import Control from 'react-leaflet-custom-control'
import { Button, ToggleButton } from "react-bootstrap";
// import "leaflet/dist/leaflet.css";
// import "leaflet-extra-markers";
// import "font-awesome/css/font-awesome.css";
// import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import '../styles/MapPage.css'
import { useState, useContext, useEffect, useRef } from "react";
import { UserContext, ComplexesFilterContext } from "../context";
import { useSearchParams } from "react-router-dom";
import NotificationControl from '../libs/NotificationControl/NotificationControl'


const MapPage = () => {
    const [map, setMap] = useState(null);
    const [modalSearchVisibility, setModalSearchVisibility] = useState(false)
    const [modalStatVisibility, setModalStatVisibility] = useState(false)
    const [modalUsersVisibility, setModalUsersVisibility] = useState(false)
    const notify = useRef()
    const [searchParams, setSearchParams] = useSearchParams();
    const { userPayload } = useContext(UserContext)
    const { complexesFilterPayload, setComplexesFilterPayload } = useContext(ComplexesFilterContext)
    const { roles } = userPayload



    const closeModal = () => {
        setModalSearchVisibility(false)
        setModalStatVisibility(false)
        setModalUsersVisibility(false)
    }
    const hostSelected = (host, layer) => {
        localStorage.setItem(layer, 1)
        // localStorage.setItem('layer_speed', 1)
        // localStorage.setItem('layer_crossroad', 1)
        // localStorage.setItem('layer_deact', 1)
        localStorage.setItem('severity', 0)
        setComplexesFilterPayload({...complexesFilterPayload, severity: 0})
        console.log(map)
        setModalSearchVisibility(false)
        map.flyTo([host.inventory.location_lat, host.inventory.location_lon], 13, {
            duration: 1, // Animation duration in milliseconds
            easeLinearity: 0.7 // Adjust animation smoothness
        });
        setTimeout(() => {
            map.eachLayer(function (layer) {
                if (layer.options.data === host.hostid) {
                    layer.openPopup()
                } else if (layer.options.pane) {
                    var childs = []
                    try {
                        childs = layer.getAllChildMarkers()
                    } catch {
                        
                    }
                    const complex = childs.find(child => child.options.data === host.hostid)
                    if (complex) {
                        layer.zoomToBounds()
                        setTimeout(() => {
                            complex.openPopup()
                        }, 500)
                    }
                }
            })   
        }, 1500)   
    }
    const severities = [
        { level: 0, color: "#009549", name: "all"},
        { level: 1, color: "#28a1d7", name: "info"},
        { level: 2, color: "#f5bb3a", name: "warn"},
        { level: 3, color: "#ef9227", name: "awerage"},
        { level: 4, color: "#d63a23", name: "high"},
        { level: 5, color: "#a23337", name: "disaster"},
    ]

    

    useEffect(() => {
        if(localStorage.getItem('severity') === null) {
            localStorage.setItem('severity', 0)
        }
    }, [])

    useEffect(() => {
        if (map && searchParams.get('hostid')) {
            setTimeout(() => {
                hostSelected({
                    hostid: searchParams.get('hostid'),
                    inventory: {
                        location_lat: searchParams.get('lat'),
                        location_lon: searchParams.get('lon')
                    }
                })
            }, 1000)
            
        }
    }, [map])

    return (
        <div className="map-page">
            <MapContainer
                center={[42.65, 48.3]}
                zoom={9}
                scrollWheelZoom={true}
                attributionControl={false}
                ref={setMap}
            >
                <ControlLayer notify={notify.current} ccc={123}/>
                <Control position='bottomright' style>
                    <Button className='btn_control' variant="light"  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px'}} onClick={() => {
                        setModalSearchVisibility(true)
                    }} >
                        {/* <FontAwesomeIcon icon={icon({ name: 'search' })} /> */}
                        <svg width={15} height={15} className="control_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                            fill="black"
                        /></svg>
                    </Button>
                    <Button className='btn_control' variant="light" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px'}} onClick={() => {
                        setModalStatVisibility(true)
                    }} >
                        {/* <FontAwesomeIcon icon={icon({ name: 'chart-bar' })} /> */}
                        <svg className="control_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" d="M32 32c17.7 0 32 14.3 32 32l0 336c0 8.8 7.2 16 16 16l400 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L80 480c-44.2 0-80-35.8-80-80L0 64C0 46.3 14.3 32 32 32zm96 96c0-17.7 14.3-32 32-32l192 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-192 0c-17.7 0-32-14.3-32-32zm32 64l128 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32zm0 96l256 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-256 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
                            fill="black"
                        /></svg>
                    </Button>
                    {roles && roles.includes('admin') ?
                    <Button className='btn_control' variant="light" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '6px'}} onClick={() => {
                        setModalUsersVisibility(true)
                    }} >
                        <svg className="control_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path xmlns="http://www.w3.org/2000/svg" d="M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z"
                            fill="black"
                        /></svg>
                        {/* <FontAwesomeIcon icon={icon({ name: 'user' })} /> */}
                    </Button>
                    : null}
                </Control>
                
                <Control position='bottomleft' >
                    <div style={{ padding: "3px", background: "white", justifyContent: 'center', alignItems: 'center', display:'flex', borderRadius: '6px', border: '1px solid black' }}>
                        {/* <div style={{ marginBottom: "2px" }}>Уровень проблемы (0-5)</div> */}
                        {/* <div style={{ display: "flex", justifyContent: "spaceBetween", padding: "5px", gap: "5px" }}> */}
                            {/* <div style={{ background: "#009549", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 0 </div>
                            <div style={{ background: "#28a1d7", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 1 </div>
                            <div style={{ background: "#f5bb3a", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 2 </div>
                            <div style={{ background: "#ef9227", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 3 </div>
                            <div style={{ background: "#d63a23", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 4 </div>
                            <div style={{ background: "#a23337", color: "white", width: "18px", borderRadius: "50%", textAlign: "center" }}> 5 </div> */}
                        {
                            severities.map(s => {
                                return <ToggleButton
                                // className="mb-2"
                                style={{ width: "20px", height: "20px", borderRadius: "50%", textAlign: "center", 
                                    "--bs-btn-border-width": '5px',
                                    "--bs-btn-border-color": `${s.color}`,
                                    "--bs-btn-active-bg": `${s.color}`,
                                    "--bs-btn-active-border-color": `${s.color}`,
                                    marginBottom: '2px'
                                    }}
                                key={s.name}
                                id={s.name}
                                type="checkbox"
                                variant="outline-success"
                                checked={(complexesFilterPayload.severity <= s.level) ? true : false}
                                value="1"
                                onChange={(e) => {
                                    setComplexesFilterPayload({...complexesFilterPayload, severity: s.level})
                                    localStorage.setItem('severity', s.level)
                                }   }                         
                                ></ToggleButton>
                            })
                        }
                        
                           
      
                        {/* </div> */}

                    </div>
                </Control>
                <NotificationControl 
                    ref={notify} 
                    timeout={30000}
                    className={'modern'}
                />
            </MapContainer>
            <ModalInfo />
            <ModalSearch visible={modalSearchVisibility} close={closeModal} select={hostSelected} />
            <ModalStat visible={modalStatVisibility} close={closeModal}/>
            {roles && roles.includes('admin') ? <ModalUsers visible={modalUsersVisibility} close={closeModal}/> : null}
            
            
        </div>
    )
}

export default MapPage