import { $authHost } from ".";

export async function getState() {
    const { data } = await $authHost.get('/api/state')
    // console.log(data)
    return data
}

export async function getGraphs(hostid, name, from ,to) {
    try {
        // console.log(hostid)
        const { data } = await $authHost.post('/api/graphs', {hostid, name, from, to})
        return data
    } catch(e) {
        console.log(e)
    }
}

export async function execScript({hostid, name}, script, notify) { 
    const scriptid = script.id
    $authHost.post('/api/script/exec', {hostid, scriptid}, { timeout: 30000}).then(res => {
        // console.log(res)
        notify('success', {head: name, body: `${script.name}: успешно выполнено`})
    }).catch(err => {
        console.log(err)
        notify('alert', {head: name, body: `${script.name}: ошибка`})
    })
    
}


export async function getFrame(hostid, time) {
    try {
        // console.log(time)
        const { data } = await $authHost.post(`/api/frames/single`, {hostid, time}, { timeout: 30000})
        // console.log(data)
        return data

    } catch(e) {
        console.log(e)
        throw e
    } 
}

export async function getFramesTimeLine(hostid) {
    try {
        // console.log(hostid)
        const { data } = await $authHost.post(`/api/frames/timeline`, {hostid}, { timeout: 30000})
        // console.log(data)
        return data
    } catch(e) {
        console.log(e)
    } 
}
