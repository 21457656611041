import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { getProblems, getAcknowldgedTriggerUrl, violationsFixations } from "../helper";
import React from "react";
import { useContext } from "react";
import { CurrentComplexContext, UserContext } from "../../../context";
// import DropdownLinks from "./DropdownLinks";
// import DropdownScripts from "./DropdownScripts";
import '../../../styles/MarkerPopup.css'

const MarkerPopup = props => {
    // console.log(props.complex)
    // const { violations, fixations } = violationsFixations(props.complex);
    // const { setCurrentComplexPayload } = useContext(CurrentComplexContext)
    // const { userPayload } = useContext(UserContext)
    // const { roles } = userPayload
    // const problems = getProblems(props.complex)
    // const acknowledge_url= getAcknowldgedTriggerUrl(props.complex)
    // const infoClick = () => {
    //     setCurrentComplexPayload({ modal: true, class: 'speed', complex: props.complex })
    // }

    const lat = props.vehicle.items.find(item => item.key_.includes('_lat'))?.lastvalue
    const lon = props.vehicle.items.find(item => item.key_.includes('_lon'))?.lastvalue
    const time = props.vehicle.items.find(item => item.key_.includes('_time'))?.lastvalue
    const speed = props.vehicle.items.find(item => item.key_.includes('_speed'))?.lastvalue
    const course = props.vehicle.items.find(item => item.key_.includes('_course'))?.lastvalue
    const problems = getProblems(props.vehicle)
    return (
        <Popup maxWidth={400} >

            <div className='popup_header'>
                <span style={{ fontWeight: 600 }}>{props.vehicle.name} </span>
            </div>
            <hr style={{ margin: '5px' }} />

            {/* <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => window.open(`https://yandex.ru/maps/?whatshere[point]=${lon},${lat}&whatshere[zoom]=17`)}>{lat}, {lon}</span>
                <span className="popup_body_badge popup_body_badge_date">{(new Date(parseInt(time) * 1000)).toLocaleString('ru')}</span>
                
                <br />
            </div>
            <hr style={{ margin: '5px' }} /> */}
            <div key={'popup_line_1'} style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap' }}>
                <div style={{ display: "flex", marginRight: '40px' }}>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233 7c-9.4-9.4-24.6-9.4-33.9 0l-96 96c-9.4 9.4-9.4 24.6 0 33.9l89.4 89.4-15.5 15.5C152.3 230.4 124.9 224 96 224c-31.7 0-61.5 7.7-87.8 21.2c-9 4.7-10.3 16.7-3.1 23.8L112.7 376.7 96.3 393.1c-2.6-.7-5.4-1.1-8.3-1.1c-17.7 0-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32c0-2.9-.4-5.6-1.1-8.3l16.4-16.4L242.9 506.9c7.2 7.2 19.2 5.9 23.8-3.1C280.3 477.5 288 447.7 288 416c0-28.9-6.4-56.3-17.8-80.9l15.5-15.5L375 409c9.4 9.4 24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9l-89.4-89.4 55-55c12.5-12.5 12.5-32.8 0-45.3l-48-48c-12.5-12.5-32.8-12.5-45.3 0l-55 55L233 7zm159 351l-72.4-72.4 62.1-62.1L454.1 296 392 358.1zM226.3 192.4L153.9 120 216 57.9l72.4 72.4-62.1 62.1z"
                            fill="navy"
                        />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => lat && lon ? window.open(`https://yandex.ru/maps/?whatshere[point]=${lon},${lat}&whatshere[zoom]=17`) : null}>{lat && lon ? `${lat}, ${lon}` : 'Нет данных'}</span>
                </div>
                <div style={{ display: "flex"  }}>
                    <span className="popup_body_badge popup_body_badge_fixations">{speed ? speed : '?'}</span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path xmlns="http://www.w3.org/2000/svg" d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm320 96c0-15.9-5.8-30.4-15.3-41.6l76.6-147.4c6.1-11.8 1.5-26.3-10.2-32.4s-26.2-1.5-32.4 10.2L262.1 288.3c-2-.2-4-.3-6.1-.3c-35.3 0-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64z"
                                fill="green"
                            />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                </div>
            </div>

            <div key={'popup_line_2'} style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap' }}>
                <div style={{ display: "flex" , marginRight: '40px' }}>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                            fill="brown"
                        />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                </div>
                <div style={{ display: "flex" }}>
                    <span className="popup_body_badge popup_body_badge_violations">{course ? course : '?'}</span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">

                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8l176 0 0 176c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"
                                fill="purple"
                            />
                        </svg>
                    </span>

                    {/* <span className="popup_hr_line" style={{ paddingBottom: '2px' }}>-</span> */}
                    <hr className="popup_hr" style={{ width: '5px' }} />
                </div>

            </div>
            <div key={'popup_line_4'} style={{ display: "flex", flexDirection: 'row' }}>
                <hr className="popup_hr" style={{ width: '100%' }} />
                <span className="popup_hr_text">
                    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                            fill="#fd7e14"
                        />
                    </svg>
                </span>
                <hr className="popup_hr" style={{ width: '5px' }} />
            </div>
            {
                problems.length === 0
                    ?
                    <div key={'popup_problems'} className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_host">{element[1].split('/')[1]}</span> */}
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                                {/* <span className="popup_body_badge popup_body_badge_alerts_duration">{element[3]}</span> */}
                            </div>

                        )
                    })
            }
            <hr style={{ margin: '5px' }} />
{/* 
            <hr style={{ margin: '5px' }} />
            <div className='popup_footer'>
              
                
            
                <Button size="sm" variant="primary" onClick={infoClick}> Подробно </Button>
            
            </div> */}

            {/* <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>{props.complex.name} </span>
                <br />
                <p />
                [{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}]
            </div>
            <div>
                <p />
                {(new Date()).toLocaleString('ru')}
            </div>
            <div style={{ fontSize: "12px" }}>
                <br />
                <span style={{ color: "green" }}>Проездов в течение дня: {fixations}</span>
                <br />
                <span style={{ color: "red" }}>Нарушений в течение дня: {violations} </span>
                <br />
            </div>
            <br />
            <div style={{ fontSize: "12px" }}>
                <ul>
                    {
                        problems.length === 0
                            ?
                            <li><span style={{ color: "green" }} key={0}>Работа в штатном режиме</span></li>
                            : problems.map(element => {
                                return (
                                    <li key={`${element.key_}_${props.complex.hostid}`}><span style={{ color: "red" }}> {element[0]} : {element[1]}</span></li>
                                )
                            })
                    }
                </ul>
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "right" }}>
                <Button size="sm" variant="outline-primary" onClick={infoClick}>Подробно</Button>
            </div> */}
        </Popup>
    )
}


export default MarkerPopup;
