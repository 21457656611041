import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-datepicker/dist/react-datepicker.module.css'
import { useContext, useState } from 'react';
import { CurrentComplexContext } from '../../context';
import ModalContentCrossroad from './crossroad/ModalContent';
import ModalContentSpeed from './speed/ModalContent';
import '../../styles/ModalInfo.css'
import '../../styles/Modal.css'
import ModalContentVgk from './vgk/ModalContent';


const ModalInfo = props => {
    const { currentComplexPayload, setCurrentComplexPayload } = useContext(CurrentComplexContext)
    const [ style, setStyle] = useState('modal_info_body')

    function changeStyle(param) {
        console.log(param)
        setStyle(param)
    }

    return (
        currentComplexPayload.complex ?
            <Modal show={currentComplexPayload.modal}
                size="xl"
                // dialogClassName="modal-100w"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onEscapeKeyDown={() => setCurrentComplexPayload({ modal: false })}
                scrollable
                
            >
                <Modal.Header className='modal_info_header'>
                    <Modal.Title className='modal_info_header_title'>{currentComplexPayload.complex.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={style}>
                    {
                        currentComplexPayload.class === 'crossroad' ?
                        <ModalContentCrossroad complex={currentComplexPayload.complex} style={changeStyle}/> :
                        (currentComplexPayload.class === 'vgk' ? <ModalContentVgk complex={currentComplexPayload.complex}/> : <ModalContentSpeed complex={currentComplexPayload.complex}/>)
                
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button size="sm" variant="primary" onClick={() => setCurrentComplexPayload({ modal: false })}>
                        Закрыть
                    </Button>
                </Modal.Footer>
            </Modal>
            :
            null
    )
}

export default ModalInfo
