import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, Row } from 'react-bootstrap';
import { getFrame, getFramesTimeLine } from '../../../http/complexesApi';
import 'react-photo-view/dist/react-photo-view.css';
import { unixTimeToString } from '../../../utils/converters';
import '../../../styles/ComplexImages.css'
import { OrbitProgress } from 'react-loading-indicators';
import { createPortal } from 'react-dom'

const ComplexImages = props => {
    const coeff = 1000 * 60 * 5;
    const [hostid, setHostid] = useState(null)
    const [currentHost, setCurrentHost] = useState(null)
    const containerRef = useRef();
    const selectedTimeline = useRef();
    const [timeline, setTimeline] = useState([])
    const [image, setImage] = useState(null)

    // const [imageScale, setImageScale] = useState(2)
    const [isPanning, setPanning] = useState(false);
    const [position, setPosition] = useState({
        oldX: 0,
        oldY: 0,
        x: 0,
        y: 0,
        z: 1,
    });
    const [rounded, setRounded] = useState(new Date(Math.round(new Date().getTime() / coeff) * coeff + coeff))

    const [fullWindow, setFullWindow] = useState(false); // состояние модального окна

    // const fullWindow = (index) => {
    //     console.log(props)
    //     // props.style('modal_info_body_verflow')
    //     setIsOpen(true);
    // };

    // const closeImage = () => {
    //     // props.style('modal_info_body')
    //     setIsOpen(false);
    // }


    var hosts = []

    const rtsp_frames = props.complex.macros.find(macro => macro.macro.includes("RTSP_FRAMES"))
    console.log(rtsp_frames)
    let values = '';
    let descriptions = '';
    if (rtsp_frames) {
        values = rtsp_frames.value.split(',')
        descriptions = rtsp_frames.description.split(',')
        const ip_main = props.complex.interfaces.find((i) => i.type === '1' && i.ip.includes('10.8.'))?.ip
        const ip_sec = props.complex.interfaces.find((i) => i.type === '1' && i.ip.includes('10.2.'))?.ip
        values.forEach(value => {
            const port = props.complex.macros.find(macro => macro.macro.includes(value))?.value
            hosts = [...hosts, {hostid: `${props.complex.hostid}/${value}`, name: descriptions[values.indexOf(value)], url: `http://${port.includes('080') ? ip_sec : ip_main}:${port}`}]
        })
    }
    
    // console.log(hosts)
    if (hosts.length > 0 && !hostid) {
        setHostid(hosts[0].hostid)
        // setCurrentHost(hosts[0])
    }

    function updateImage(imageid) {
        getFrame(hostid, imageid)
            .then(res => setImage(res))
            .catch(err => {
                if (!image) {
                    setImage(null)
                }
            })
    
    }

    const onLoad = (e) => {
        console.log(e)
        setImage({
            ...image,
            width: e.target.naturalWidth || 1280,
            height: e.target.naturalHeight || 720,
        });
    };

    

    const onWheel = (e) => {
        console.log(image)
        if (image && e.deltaY && containerRef.current) {
            const sign = Math.sign(e.deltaY) / 10;
            const scale = 1 - sign;
            const rect = containerRef.current.getBoundingClientRect();

            setPosition({
                ...position,
                x: position.x * scale - (rect.width / 2 - e.clientX + rect.x) * sign,
                y: position.y * scale - (image.height * rect.width / image.width / 2 - e.clientY + rect.y) * sign,
                z: position.z * scale,
            });
        }
    };

    const onMouseDown = (e) => {
        e.preventDefault();
        setPanning(true);
        setPosition({
            ...position,
            oldX: e.clientX,
            oldY: e.clientY
        });
    };
    const onKeyDown = (e) => {
        if (e.code) {
            switch (e.code) {
                case "ArrowRight":
                    if (timeline.indexOf(image.time) !== timeline.length - 1) {
                        updateImage(timeline[timeline.indexOf(image.time) + 1])
                    }
                    break;
                case "ArrowLeft":
                    if (timeline.indexOf(image.time) !== 0) {
                        updateImage(timeline[timeline.indexOf(image.time) - 1])
                    }
                    break;
                default:
                    setFullWindow(false);
            }
        }
        console.log(e)
    };

    useEffect(() => {
        setPosition({
            oldX: 0,
            oldY: 0,
            x: 0,
            y: 0,
            z: 1,
        })
        const int = setInterval(() => {
            setRounded(new Date(Math.round(new Date().getTime() / coeff) * coeff + coeff))
            getFramesTimeLine(hostid).then(res => { setTimeline(res) })
        }, 60 * 1000)
        return () => clearInterval(int)
    }, [coeff, hostid])

    useEffect(() => {
        // console.log(hostid)
        setCurrentHost(hosts.find(h => h.hostid === hostid))
        setImage(null)
        setTimeline(null)
        if (hostid) {
            getFramesTimeLine(hostid).then(res => {
                setTimeline(res)
                console.log(res)

            }).catch(err => {
                setTimeline([])
            })
        }

    }, [hostid])

    useEffect(() => {
        // console.log(hostid)
        if (timeline && timeline.length >0) {
            updateImage(timeline[timeline.length - 1])
        }
    }, [timeline])



    useEffect(() => {
        if (selectedTimeline.current) {
            selectedTimeline.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest"
            });
        }
    }, [image])

    useEffect(() => {
        const mouseup = () => {
            setPanning(false);
        };

        const mousemove = (event) => {
            if (isPanning) {
                setPosition({
                    ...position,
                    x: position.x + event.clientX - position.oldX,
                    y: position.y + event.clientY - position.oldY,
                    oldX: event.clientX,
                    oldY: event.clientY,
                });
            }
        };

        window.addEventListener('mouseup', mouseup);
        window.addEventListener('mousemove', mousemove);

        return () => {
            window.removeEventListener('mouseup', mouseup);
            window.removeEventListener('mousemove', mousemove);
        };
    });

    const createBody = (f) => {
        console.log(f)
        return (
            <div key={2} 
                
                style={ f ? {
                    position: "absolute",
                    left: 0,
                    top: 0,
                    // top: window.innerWidth > 500 ? "-8.5rem" : "-7rem",
                    // left: window.innerWidth > 500 ? "calc((var(--bs-modal-width) - " + window.innerWidth + "px)/2 - 1px)" : "calc((var(--bs-modal-margin) * -1) - 1px)",
                    // left: "calc(var(--bs-modal-width)/2", // - " + window.innerWidth + ")",
                    width: window.innerWidth,
                    height: window.innerHeight,
                    // height: window.innerWidth > 500 ? window.innerHeight - 28 : window.innerHeight,
                    backgroundColor: "rgba(0, 0, 0, 0.9)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1200,
                }
                : { display: 'flex', flexDirection: 'column', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}

                // onM={() => closeImage()}
                // tabIndex={0}
                onKeyDown={onKeyDown}
            // onLoad={e => console.log(e)}
            // onDoubleClick={() => closeImage()}
            >
                {f ?
                    <div style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setFullWindow(false)}>

                        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                fill='white'
                            />
                        </svg>
                    </div> : null
                }

                    
              


                <div ref={containerRef}
                    onWheel={onWheel}
                    onMouseDown={onMouseDown}
                    style={{ overflow: 'hidden'}}
                >

                    <div style={f ? { transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})`,display: 'flex', justifyContent: 'center' } : {display: 'flex', justifyContent: 'center'}} >
                        {image ?
                            <img
                                src={image.img}
                                alt={image.time}
                                style={f ? {   maxWidth: "100%", maxHeight: window.innerHeight * 0.8, marginBottom: "5px" } : {  maxWidth: "100%",maxHeight: window.innerHeight * 0.6, marginBottom: "5px" }}
                                onDoubleClick={() => setPosition({
                                    oldX: 0,
                                    oldY: 0,
                                    x: 0,
                                    y: 0,
                                    z: 1,
                                })}
                                onLoad={onLoad}
                                onClick={() => f ? null : setFullWindow(true)} 
                            />
                            :
                            <div key={3} style={{ width: "100%", height: "420px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                                <OrbitProgress color="green" size="medium" text="" textColor="" />
                            </div>
                        }
                    </div>
                    
                </div>


                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5, justifyContent: 'center' }} >

                    {
                        image && timeline.indexOf(image.time) !== 0 ?
                            <>
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={() => updateImage(timeline[0])} style={{ marginLeft: 20,marginRight: 20, filter: "drop-shadow( 0px 0px 5px rgba(255, 0, 0, .8))"}}>
                                                <path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"
                                                    fill={f ? 'white' : 'black'}
                                                />
                                    </svg>
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" onClick={() => updateImage(timeline[timeline.indexOf(image.time) - 1])} style={{ marginRight: 20 , filter: "drop-shadow( 0px 0px 5px rgba(255, 0, 0, .8))"}}>
                                        <path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29l0-320c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241 64 96c0-17.7-14.3-32-32-32S0 78.3 0 96L0 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-145 11.5 9.6 192 160z"
                                            fill={f ? 'white' : 'black'}
                                        />
                                    </svg>
                                {/* <button style={{ marginLeft: 20, paddingLeft: 5, paddingRight: 5, paddingBottom: 3, marginRight: 20, borderRadius: 5 }}  onClick={() => updateImage(timeline[0])}>
                                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"
                                            fill='black'
                                        />
                                    </svg>
                                </button>
                                <button style={{ marginRight: 20, paddingLeft: 5, paddingBottom: 3, paddingRight: 5, borderRadius: 5 }} onClick={() => updateImage(timeline[timeline.indexOf(image.time) - 1])}>
                                    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29l0-320c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241 64 96c0-17.7-14.3-32-32-32S0 78.3 0 96L0 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-145 11.5 9.6 192 160z"
                                            fill='black'
                                        />
                                    </svg>
                                </button> */}
                            </>
                            : null
                    }
                    {
                        image && image.time ?
                            <span style={{ fontFamily: 'Consolas', color: fullWindow ? "white" : null, fontWeight: 'bold', filter: "drop-shadow( 0px 0px 5px rgba(0, 255, 0, .2))" }}>{unixTimeToString(image.time / 1000)}</span>
                            : null
                    }
                    {
                        image && timeline.indexOf(image.time) !== timeline.length - 1 ?
                            <>
                                {/* <Button style={{ marginLeft: 20, padding: 5 }} onClick={() => updateImage(timeline[timeline.indexOf(image.time) + 1])}> */}
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style={{ marginLeft: 20, filter: "drop-shadow( 0px 0px 5px rgba(255, 0, 0, .8))"}} onClick={() => updateImage(timeline[timeline.indexOf(image.time) + 1])}>
                                        <path xmlns="http://www.w3.org/2000/svg" d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416L0 96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241l0-145c0-17.7 14.3-32 32-32s32 14.3 32 32l0 320c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-145-11.5 9.6-192 160z"
                                            fill={f ? 'white' : 'black'}
                                        />
                                    </svg>
                                {/* </Button> */}
                                {/* <Button style={{ marginRight: 20, padding: 5, marginLeft: 20 }} onClick={() => updateImage(timeline[timeline.length - 1])}> */}
                                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ marginRight: 20, marginLeft: 20, filter: "drop-shadow( 0px 0px 5px rgba(255, 0, 0, .8))" }} onClick={() => updateImage(timeline[timeline.length - 1])}>
                                        <path xmlns="http://www.w3.org/2000/svg" d="M18.4 445c11.2 5.3 24.5 3.6 34.1-4.4L224 297.7 224 416c0 12.4 7.2 23.7 18.4 29s24.5 3.6 34.1-4.4L448 297.7 448 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-320c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 118.3L276.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S224 83.6 224 96l0 118.3L52.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S0 83.6 0 96L0 416c0 12.4 7.2 23.7 18.4 29z"
                                            fill={f ? 'white' : 'black'}
                                        />
                                    </svg>
                                {/* </Button> */}
                            </>
                            : null
                    }
                </div>
                {/* <div key={4} style={{ width: '90%', height: 1, backgroundColor: '#000000', marginTop: 10 }} /> */}
                <div key={5} style={{ display: 'flex', flexDirection: 'row', width: '90%', overflowX: 'scroll', whiteSpace: 'nowrap', scrollBehavior: 'smooth', scrollbarWidth: "thin", scrollbarColor: "#22B14C #ccc", paddingTop: 5 }}> {
                    [...Array(289).keys()].reverse().map(i => {
                        // var coeff = 1000 * 60 * 5;
                        // var date = new Date();  //or use any other date
                        // var rounded = new Date(Math.round(date.getTime() / coeff) * coeff + coeff)
                        const dt = new Date(rounded.valueOf() - (i + 1) * 300 * 1000)
                        const start = dt.valueOf() - 300 * 1000
                        const end = dt.valueOf()
                        var time = timeline && timeline.find(time => time < end && time > start)
                        return <div key={i + 10} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 10, margin: 2 }}>
                            {
                                time ?
                                    (
                                        image && image.time < end && image.time > start ?
                                            <div ref={selectedTimeline} key={time} className='timeline_point_active' id='active' style={f ? { border: "1px solid white" } : {}} />
                                            : <div className='timeline_point' onClick={() => updateImage(time)} style={f ? { border: "1px solid white" } : {}} />
                                    )
                                    : <div className='timeline_point_black' />
                            }
                            <div style={{ width: 1, height: 2, backgroundColor: "#ccc" }} />

                            {
                                i % 4 === 0 ?
                                    <span style={{ fontSize: 11, paddingTop: 2, color: "#999"}}>
                                        {dt.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}
                                    </span>
                                    : null
                            }
                        </div>
                    })
                }

                </div>
            </div>
        )
    }


    return (
        <>
            <Form>
                <Row key={0} >
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
                    <Form.Select className={"modal_info_images_form_select"} onChange={(e) => {
                        setHostid(e.target.value)
                        // setCurrentHost(e.target.value)
                        // console.log(e.target.value)
                    }}>
                        {
                            hosts.sort((a, b) => a.name.localeCompare(b.name)).map(c => {
                                return <option key={c.hostid} value={c.hostid} >{c.name}</option>
                            })
                        }
                    </Form.Select>
                    {/* <span style={{ marginLeft: 15}} href='https://ya.ru'> */}

                    <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={() => { window.open(currentHost.url)}} style={{ alignSelf: 'center', marginLeft: 10, filter: "drop-shadow( 0px 0px 5px rgba(0, 0, 255, .8))"}}>
                        <path xmlns="http://www.w3.org/2000/svg" d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-128c0-17.7-14.3-32-32-32L352 0zM80 32C35.8 32 0 67.8 0 112L0 432c0 44.2 35.8 80 80 80l320 0c44.2 0 80-35.8 80-80l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 112c0 8.8-7.2 16-16 16L80 448c-8.8 0-16-7.2-16-16l0-320c0-8.8 7.2-16 16-16l112 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 32z"
                            fill='black'
                            />
                        </svg>
                    {/* </span> */}
                    </div>
                </Row>

                <Row key={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <hr style={{ margin: '10px' }} />
                    {createBody(false)}
                    {/* 
                    <div key={2} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                        <div style={{ overflow: 'hidden' }}>
                            {
                                image ?
                                    <img
                                        src={image.img}
                                        alt={image.time}
                                        style={{ minWidth: "100%", maxWidth: "100%", maxHeight: "100%", marginBottom: "20px" }}
                                        onDoubleClick={() => setPosition({
                                            oldX: 0,
                                            oldY: 0,
                                            x: 0,
                                            y: 0,
                                            z: 1,
                                        })}
                                        onLoad={onLoad}
                                        onClick={() => fullWindow()}

                                    />
                                    : <div key={3} style={{ width: "100%", height: "450px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                                        <OrbitProgress color="green" size="medium" text="" textColor="" />
                                    </div>
                            }
                        </div>
                        <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 10, justifyContent: 'center' }} >
                            {
                                image && timeline.indexOf(image.time) !== 0 ?
                                    <>
                                        <Button style={{ left: 0, marginLeft: 10, padding: 5, marginRight: 10 }} onClick={() => updateImage(hostid, timeline[0])}>
                                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"
                                                    fill='white'
                                                />
                                            </svg>
                                        </Button>
                                        <Button style={{ left: 0, marginRight: 10, padding: 5 }} onClick={() => updateImage(hostid, timeline[timeline.indexOf(image.time) - 1])}>
                                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29l0-320c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241 64 96c0-17.7-14.3-32-32-32S0 78.3 0 96L0 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-145 11.5 9.6 192 160z"
                                                    fill='white'
                                                />
                                            </svg>
                                        </Button>
                                    </>
                                    : null
                            }
                            {
                                image && image.time ?
                                    <span style={{ fontFamily: 'Consolas', color: "black", right: 0, left: 'auto' }}>{unixTimeToString(image.time / 1000)}</span>
                                    : null
                            }
                            {
                                image && timeline.indexOf(image.time) !== timeline.length - 1 ?
                                    <>
                                        <Button style={{ position: 'relative', right: 0, marginLeft: 10, padding: 5 }} onClick={() => updateImage(hostid, timeline[timeline.indexOf(image.time) + 1])}>
                                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path xmlns="http://www.w3.org/2000/svg" d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416L0 96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241l0-145c0-17.7 14.3-32 32-32s32 14.3 32 32l0 320c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-145-11.5 9.6-192 160z"
                                                    fill='white'
                                                />
                                            </svg>
                                        </Button>
                                        <Button className="outline-success" style={{ position: 'relative', right: 0, marginRight: 10, padding: 5, marginLeft: 10 }} onClick={() => updateImage(hostid, timeline[timeline.length - 1])}>
                                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path xmlns="http://www.w3.org/2000/svg" d="M18.4 445c11.2 5.3 24.5 3.6 34.1-4.4L224 297.7 224 416c0 12.4 7.2 23.7 18.4 29s24.5 3.6 34.1-4.4L448 297.7 448 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-320c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 118.3L276.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S224 83.6 224 96l0 118.3L52.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S0 83.6 0 96L0 416c0 12.4 7.2 23.7 18.4 29z"
                                                    fill='white'
                                                />
                                            </svg>
                                        </Button>
                                    </>
                                    : null
                            }
                        </div>
                    </div>

                    <div key={5} style={{ display: 'flex', flexDirection: 'row', width: '95%', overflowX: 'auto', whiteSpace: 'nowrap', scrollBehavior: 'smooth', scrollbarWidth: "thin", scrollbarColor: "#22B14C #ccc" }}> {
                        [...Array(289).keys()].reverse().map(i => {
                            const dt = new Date(rounded.valueOf() - (i + 1) * 300 * 1000)
                            const start = dt.valueOf() - 300 * 1000
                            const end = dt.valueOf()
                            var time = timeline && timeline.find(time => time < end && time > start)
                            return <div key={i + 10} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 10, margin: 2 }}>
                                {
                                    time ?
                                        (
                                            image && image.time < end && image.time > start ?
                                                <div key={time} className='timeline_point_active' id='active' />
                                                : <div className='timeline_point' onClick={() => updateImage(hostid, time)} />
                                        )
                                        : <div className='timeline_point_black' />
                                }
                                <div style={{ width: 1, height: 6, backgroundColor: "#ccc" }} />

                                {
                                    i % 4 === 0 ?
                                        <span style={{ fontFamily: "Consolas", fontSize: 12, marginTop: 4, color: "#999" }}>
                                            {dt.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}
                                        </span>
                                        : null
                                }
                            </div>
                        })
                    }

                    </div> */}

                </Row>

            </Form>
            {createPortal(
                fullWindow ? createBody(true)
                    // <div
                    //     style={{
                    //         position: "absolute",
                    //         left: 0,
                    //         top: 0,
                    //         // top: window.innerWidth > 500 ? "-8.5rem" : "-7rem",
                    //         // left: window.innerWidth > 500 ? "calc((var(--bs-modal-width) - " + window.innerWidth + "px)/2 - 1px)" : "calc((var(--bs-modal-margin) * -1) - 1px)",
                    //         // left: "calc(var(--bs-modal-width)/2", // - " + window.innerWidth + ")",
                    //         width: window.innerWidth,
                    //         height: window.innerHeight,
                    //         // height: window.innerWidth > 500 ? window.innerHeight - 28 : window.innerHeight,
                    //         backgroundColor: "rgba(0, 0, 0, 0.9)",
                    //         display: "flex",
                    //         flexDirection: "column",
                    //         alignItems: "center",
                    //         justifyContent: "center",
                    //         zIndex: 1200,
                    //     }}

                    //     // onM={() => closeImage()}
                    //     // tabIndex={0}
                    //     onKeyDown={onKeyDown}
                    // // onLoad={e => console.log(e)}
                    // // onDoubleClick={() => closeImage()}
                    // >
                    //     <div style={{ position: 'absolute', top: 10, right: 10 }} onClick={() => closeImage()}>

                    //         <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    //             <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    //                 fill='white'
                    //             />
                    //         </svg>
                    //     </div>


                    //     <div ref={containerRef}
                    //         onWheel={onWheel}
                    //         onMouseDown={onMouseDown}
                    //         style={{ overflow: 'hidden' }}
                    //     >

                    //         <div style={{ transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})` }} >
                    //             {image ?
                    //                 <img
                    //                     src={image.img}
                    //                     alt={image.time}
                    //                     style={{ minWidth: "100%", maxWidth: "100%", maxHeight: "100%", marginBottom: "20px" }}
                    //                     onDoubleClick={() => setPosition({
                    //                         oldX: 0,
                    //                         oldY: 0,
                    //                         x: 0,
                    //                         y: 0,
                    //                         z: 1,
                    //                     })}
                    //                     onLoad={onLoad}
                    //                 />
                    //                 :
                    //                 <div key={3} style={{ width: "100%", height: "720px", display: 'flex', justifyContent: 'center', border: "1px solib black", alignItems: "center" }}>
                    //                     <OrbitProgress color="green" size="medium" text="" textColor="" />
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>


                    //     <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, justifyContent: 'center' }} >

                    //         {
                    //             image && timeline.indexOf(image.time) !== 0 ?
                    //                 <>
                    //                     <Button style={{ marginLeft: 20, padding: 5, marginRight: 20 }}  variant="outline-secondary" onClick={() => updateImage(hostid, timeline[0])}>
                    //                         <svg width="20" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    //                             <path d="M493.6 445c-11.2 5.3-24.5 3.6-34.1-4.4L288 297.7 288 416c0 12.4-7.2 23.7-18.4 29s-24.5 3.6-34.1-4.4L64 297.7 64 416c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 96C0 78.3 14.3 64 32 64s32 14.3 32 32l0 118.3L235.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S288 83.6 288 96l0 118.3L459.5 71.4c9.5-7.9 22.8-9.7 34.1-4.4S512 83.6 512 96l0 320c0 12.4-7.2 23.7-18.4 29z"
                    //                                 fill='white'
                    //                             />
                    //                         </svg>
                    //                     </Button>
                    //                     <Button style={{ marginRight: 20, padding: 5 }}  variant="outline-secondary" onClick={() => updateImage(hostid, timeline[timeline.indexOf(image.time) - 1])}>
                    //                         <svg width="20" height="21" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    //                             <path d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29l0-320c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241 64 96c0-17.7-14.3-32-32-32S0 78.3 0 96L0 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-145 11.5 9.6 192 160z"
                    //                                 fill='white'
                    //                             />
                    //                         </svg>
                    //                     </Button>
                    //                 </>
                    //                 : null
                    //         }
                    //         {
                    //             image && image.time ?
                    //                 <span style={{ fontFamily: 'Consolas', color: "white" }}>{unixTimeToString(image.time / 1000)}</span>
                    //                 : null
                    //         }
                    //         {
                    //             image && timeline.indexOf(image.time) !== timeline.length - 1 ?
                    //                 <>
                    //                     <Button style={{ marginLeft: 20, padding: 5 }} variant="outline-secondary" onClick={() => updateImage(hostid, timeline[timeline.indexOf(image.time) + 1])}>
                    //                         <svg width="20" height="21" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    //                             <path xmlns="http://www.w3.org/2000/svg" d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416L0 96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241l0-145c0-17.7 14.3-32 32-32s32 14.3 32 32l0 320c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-145-11.5 9.6-192 160z"
                    //                                 fill='white'
                    //                             />
                    //                         </svg>
                    //                     </Button>
                    //                     <Button style={{ marginRight: 20, padding: 5, marginLeft: 20 }} variant="outline-secondary" onClick={() => updateImage(hostid, timeline[timeline.length - 1])}>
                    //                         <svg width="20" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    //                             <path xmlns="http://www.w3.org/2000/svg" d="M18.4 445c11.2 5.3 24.5 3.6 34.1-4.4L224 297.7 224 416c0 12.4 7.2 23.7 18.4 29s24.5 3.6 34.1-4.4L448 297.7 448 416c0 17.7 14.3 32 32 32s32-14.3 32-32l0-320c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 118.3L276.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S224 83.6 224 96l0 118.3L52.5 71.4c-9.5-7.9-22.8-9.7-34.1-4.4S0 83.6 0 96L0 416c0 12.4 7.2 23.7 18.4 29z"
                    //                                 fill='white'
                    //                             />
                    //                         </svg>
                    //                     </Button>
                    //                 </>
                    //                 : null
                    //         }
                    //     </div>
                    //     <div key={4} style={{ width: '75%', height: 1, backgroundColor: '#000000', marginTop: 10 }} />
                    //     <div key={5} style={{ display: 'flex', flexDirection: 'row', width: '75%', overflowX: 'scroll', whiteSpace: 'nowrap', scrollBehavior: 'smooth', scrollbarWidth: "thin", scrollbarColor: "#22B14C #ccc" }}> {
                    //         [...Array(289).keys()].reverse().map(i => {
                    //             // var coeff = 1000 * 60 * 5;
                    //             // var date = new Date();  //or use any other date
                    //             // var rounded = new Date(Math.round(date.getTime() / coeff) * coeff + coeff)
                    //             const dt = new Date(rounded.valueOf() - (i + 1) * 300 * 1000)
                    //             const start = dt.valueOf() - 300 * 1000
                    //             const end = dt.valueOf()
                    //             var time = timeline && timeline.find(time => time < end && time > start)
                    //             return <div key={i + 10} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: 10, margin: 2 }}>
                    //                 {
                    //                     time ?
                    //                         (
                    //                             image && image.time < end && image.time > start ?
                    //                                 <div ref={selectedTimeline} key={time} className='timeline_point_active' id='active' style={{ border: "1px solid white" }} />
                    //                                 : <div className='timeline_point' onClick={() => updateImage(hostid, time)} style={{ border: "1px solid white" }} />
                    //                         )
                    //                         : <div className='timeline_point_black' />
                    //                 }
                    //                 <div style={{ width: 1, height: 6, backgroundColor: "#ccc" }} />

                    //                 {
                    //                     i % 4 === 0 ?
                    //                         <span style={{ fontFamily: "Consolas", fontSize: 12, paddingTop: 4, color: "#999", paddingBottom: 6 }}>
                    //                             {dt.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}
                    //                         </span>
                    //                         : null
                    //                 }
                    //             </div>
                    //         })
                    //     }

                    //     </div>
                    // </div> 
                    : null
                , document.body
            )}

        </>

    )


}

export default ComplexImages