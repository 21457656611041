import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Accordion, AccordionBody, AccordionItem } from 'react-bootstrap';
import { InputGroup, Form } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { ComplexesContext } from '../../context'
import "../../styles/ModalSearch.css"
import "../../styles/Modal.css"


const ModalSearch = props => {
    const { complexesPayload } = useContext(ComplexesContext)
    const { hosts } = complexesPayload
    const [hostsC, setHostsC] = useState([] || hosts.crossroad.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))
    const [hostsS, setHostsS] = useState([] || hosts.speed.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))
    const [hostsP, setHostsP] = useState([] || hosts.parking.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))
    const [hostsD, setHostsD] = useState([] || hosts.deactivated.sort((a, b) => {
        return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
    }))


    const filter = (text) => {
        if (text === "") { text = ' ' }
        setHostsC(hosts.crossroad.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
            // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
            // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
            .filter(host => host.name.toLowerCase()
                .includes(text.toLowerCase())))

        setHostsS(hosts.speed.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
            // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
            // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
            .filter(host => host.name.toLowerCase()
                .includes(text.toLowerCase())))

        setHostsP(hosts.parking.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
            // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
            // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
            .filter(host => host.name.toLowerCase()
                .includes(text.toLowerCase())))

        setHostsD(hosts.deactivated.sort((a, b) => {
            return a.name.split('.')[0] > b.name.split('.')[0] ? 1 : -1
        })
            // .sort((hostA, hostB) => hostA.name.split['.'][0] - hostB.name.split['.'][0])
            // .sort((a,b) => (+a.name.split('.')[0]) - (+b.name.split('.')[0]))
            .filter(host => host.name.toLowerCase()
                .includes(text.toLowerCase())))
    }

    return (
        <Modal show={props.visible}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onEscapeKeyDown={() => props.close()}
            scrollable
            onShow={() => filter(' ')}
            className='.search_modal'
        >
            <Modal.Header className='modal_search_header'>
                <Modal.Title className='modal_search_header_title'>Поиск</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal_search_body'>
                <InputGroup className="mb-3" autoFocus>
                    <InputGroup.Text id="basic-addon1" >
                        {/* <FontAwesomeIcon icon={icon({ name: 'search' })} /> */}
                        <svg className="modal_search_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"
                            fill="black"
                        /></svg>
                    </InputGroup.Text>
                    <Form.Control
                        placeholder="поиск..."
                        aria-label="поиск..."
                        aria-describedby="basic-addon1"
                        onChange={e => filter(e.target.value)}
                        style={{ fontFamily: 'Consolas' }}
                        autoFocus
                    />
                </InputGroup>

                <Accordion>
                    {
                        hostsC.length > 0 ?
                            <AccordionItem key={'item0'} eventKey='0'>
                                <Accordion.Header key={'header0'} as={'h1'}>Перекрестки</Accordion.Header>
                                <AccordionBody>
                                    {
                                        hostsC.map(host => {
                                            const current = host

                                            return <div key={host.hostid}>
                                                <Button variant="primary" size='sm' onClick={() => { props.select(current, 'layer_crossroad') }}>
                                                    {/* <FontAwesomeIcon icon={icon({ name: 'location-dot' })} /> */}
                                                    <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                                                        fill='white'
                                                    /></svg>
                                                </Button>
                                                <span className='modal_search_accordion_body'>{host.name}</span>
                                            </div>
                                        })
                                    }
                                </AccordionBody>
                            </AccordionItem>
                            : null
                    }
                    {
                        hostsS.length > 0 ?
                            <AccordionItem key={'item1'} eventKey='1'>
                                <Accordion.Header key={'header1'} as={'h1'}>Скоростные</Accordion.Header>
                                <AccordionBody>
                                    {
                                        hostsS.map(host => {
                                            const current = host

                                            return <div key={host.hostid}>
                                                <Button variant="primary" size='sm' onClick={() => { props.select(current, 'layer_speed') }}>
                                                    {/* <FontAwesomeIcon icon={icon({ name: 'location-dot' })} /> */}
                                                    <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                                                        fill='white'
                                                    /></svg>
                                                </Button>
                                                <span className='modal_search_accordion_body'>{host.name}</span>
                                            </div>
                                        })
                                    }
                                </AccordionBody>
                            </AccordionItem>
                            : null
                    }
                    {
                        hostsP.length > 0 ?
                            <AccordionItem key={'item2'} eventKey='2'>
                                <Accordion.Header key={'header2'} as={'h1'}>Парковка</Accordion.Header>
                                <AccordionBody>
                                    {
                                        hostsP.map(host => {
                                            const current = host

                                            return <div key={host.hostid}>
                                                <Button variant="primary" size='sm' onClick={() => { props.select(current, 'layer_parking') }}>
                                                    {/* <FontAwesomeIcon icon={icon({ name: 'location-dot' })} /> */}
                                                    <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                                                        fill='white'
                                                    /></svg>
                                                </Button>
                                                <span className='modal_search_accordion_body'>{host.name}</span>
                                            </div>
                                        })
                                    }
                                </AccordionBody>
                            </AccordionItem>
                            : null
                    }
                    {
                        hostsP.length > 0 ?
                            <AccordionItem key={'item3'} eventKey='3' >
                                <Accordion.Header key={'header3'} as={'h1'}>Деактивированные</Accordion.Header>
                                <AccordionBody>
                                    {
                                        hostsD.map(host => {
                                            const current = host

                                            return <div key={host.hostid}>
                                                <Button variant="primary" size='sm' onClick={() => { props.select(current, 'layer_deact') }}>
                                                    {/* <FontAwesomeIcon icon={icon({ name: 'location-dot' })} /> */}
                                                    <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
                                                        fill='white'
                                                    /></svg>
                                                </Button>
                                                <span className='modal_search_accordion_body'>{host.name}</span>
                                            </div>
                                        })
                                    }
                                </AccordionBody>
                            </AccordionItem>
                            : null
                    }
                </Accordion>

                {/* <div style={{ overflowY: "auto", height: "calc(100vh - 250px)", fontSize: "14px" }}>
                    {
                        hosts.map(host => {
                            const current = host
                            const { violations, fixations } = violationsFixations(host)

                            return (
                                <Card className='mb-2' key={host.hostid}>
                                    <Card.Body style={{ display: "flex" }}>
                                        <Button variant="primary" size='sm' onClick={
                                            () => {
                                                props.select(current)
                                            }
                                        }>
                                            <FontAwesomeIcon icon={icon({ name: 'location-dot' })} />
                                        </Button>
                                        <Card.Text style={{ paddingTop: "3px", paddingLeft: "10px"}}>
                                        {host.name}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )
                        })
                    }

                </div> */}
            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={() => {
                    props.close()
                }}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalSearch
