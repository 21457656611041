import "../../styles/ModalStat.css"
import "../../styles/Modal.css"
import getStat, { getXlsx } from "../../http/statApi";
import { Form, Row, Col, Tab, Tabs, Table } from "react-bootstrap";
import DatePicker from 'react-date-picker';
import { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from "react-bootstrap/Badge";
import fileDownload from 'js-file-download'
import '../../styles/Calendar.css'

const ModalStat = props => {
    const [interval, setInterval] = useState([new Date(new Date().setHours(0, 0, 0, 0)), new Date()])

    const [statisic, setStatisic] = useState([])

    const [fixations, setFixations] = useState(0)
    const [violations, setViolations] = useState(0)
    const [violationsWrongDir, setViolationsWrongDir] = useState(0)
    const [violationsStopLine, setViolationsStopLine] = useState(0)
    const [violationsRedLight, setViolationsRedLight] = useState(0)
    const [violationsLeftTurn, setViolationsLeftTurn] = useState(0)


    const [key, setKey] = useState('crossroads');

    // const InputStartDate = forwardRef(({ value, onClick }, ref) => (
    //     <button className="input-start" onClick={onClick} ref={ref}>
    //         {value}
    //     </button>
    // ));

    // const InputEndDate = forwardRef(({ value, onClick }, ref) => (
    //     <button className="input-end" onClick={onClick} ref={ref}>
    //         {value}
    //     </button>
    // ));
    // const action = useCallback(async () => {
    //     if (!startDate || !endDate) {
    //         console.log(startDate, endDate)
    //         return
    //     }

    //     const data = await getStat(startDate, endDate)
    //     // console.log(data)
    //     if (data) {
    //         setStatisic(data.filter(obj => obj.name))
    //         setFixations(data.reduce((acc, item) => acc + item.totalFixations, 0))
    //         setViolations(data.reduce((acc, item) => acc + item.totalViolations, 0))
    //         setViolationsWrongDir(data.reduce((acc, item) => acc + item.totalViolationsWrongDir, 0))
    //         setViolationsStopLine(data.reduce((acc, item) => acc + item.totalViolationsStopLine, 0))
    //         setViolationsRedLight(data.reduce((acc, item) => acc + item.totalViolationsRedLight, 0))
    //         setViolationsLeftTurn(data.reduce((acc, item) => acc + item.totalViolationsLeftTurn, 0))
    //     }

    // }, [startDate, endDate])

    // useEffect(() => {
    //     action().catch(e => console.log(e))
    // }, [startDate, endDate, action])

    const toExcel = async () => {
        if (!interval) {
            console.log(interval[0], interval[1])
            return
        }
        const response = await getXlsx(interval[0], interval[1])
        fileDownload(response.data, `отчет ${interval[0].toLocaleDateString()} - ${interval[1].toLocaleDateString()}.xlsx`)
    }

    useEffect(() => {
        if (interval[0].getTime() > interval[1].getTime()) {
            setInterval([interval[1], interval[1]])
        } else {
            getStat(interval[0], interval[1]).then(data => {
                if (data) {
                    setStatisic(data.filter(obj => obj.name))
                    setFixations(data.reduce((acc, item) => acc + item.totalFixations, 0))
                    setViolations(data.reduce((acc, item) => acc + item.totalViolations, 0))
                    setViolationsWrongDir(data.reduce((acc, item) => acc + item.totalViolationsWrongDir, 0))
                    setViolationsStopLine(data.reduce((acc, item) => acc + item.totalViolationsStopLine, 0))
                    setViolationsRedLight(data.reduce((acc, item) => acc + item.totalViolationsRedLight, 0))
                    setViolationsLeftTurn(data.reduce((acc, item) => acc + item.totalViolationsLeftTurn, 0))
                }

            }).catch(err => {
                console.log(err)
            })
        }

    }, [interval])

    useEffect(() => {
        setInterval([new Date(new Date().setHours(0, 0, 0, 0)), new Date()])
    }, [])


    return (
        <Modal show={props.visible}
            size="xl"
            // dialogClassName="modal-100w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            // onEscapeKeyDown={() => setCurrentComplexPayload({ modal: false })}
            scrollable

            // // size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            // centered
            // className="mb-3"
            // // fullscreen
            // scrollable
            // style={{ paddingTop: '20px', paddingBottom: '20px' }}
            onEscapeKeyDown={() => props.close()}
        // onShow={() => action().catch(e => console.log(e))}
        >
            <Modal.Header className='modal_stat_header'>
                {/* <Modal.Title >Статистика</Modal.Title> */}
                <div>
                    <div style={
                        {
                            display: 'flex',
                            justifyContent: "center",
                            flexDirection: 'row',
                            marginTop: "10px",
                            flexWrap: 'wrap'
                        }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', border: '1px  solid #dee2e6', backgroundColor: '#f8f9fa', borderRadius: '5px', margin: '2px 30px' }}>
                            <span style={{ alignContent: 'center', margin: '2px 20px' }}>Начало периода</span>
                            <DatePicker key={1} onChange={val => setInterval([val, interval[1]])} value={interval[0]} clearIcon={false} />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', border: '1px  solid #dee2e6', backgroundColor: '#f8f9fa', borderRadius: '5px', margin: '2px 30px' }}>
                            <span style={{ alignContent: 'center', margin: '2px 20px' }}> Конец  периода </span>
                            <DatePicker key={2} onChange={val => setInterval([interval[0], val])} value={interval[1]} clearIcon={false} />

                        </div>




                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '10px 30px' }}>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent' }} onClick={() => {
                            setInterval([new Date(new Date().setHours(0, 0, 0, 0)), new Date()])
                        }}>Сегодня</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent' }} onClick={() => {
                            // setInterval([new Date(new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0)), new Date(new Date().setHours(0, 0, 0, 0))])
                            const tmp = new Date(new Date(new Date().getTime() - 1*24*60*60*1000))
                            setInterval([ new Date(tmp.setHours(0,0,0,0)), new Date(tmp.setHours(23,59,59,0))])
                        }}>Вчера</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent' }} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)), new Date()])
                        }}>Последние 3 дня</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent' }} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)), new Date()])
                        }}>Последние 7 дней</button>
                        <button style={{ color: '#0d6efd', border: 0, padding: '0 10px', textDecoration: 'underline', textUnderlineOffset: '2px', backgroundColor: 'transparent' }} onClick={() => {
                            setInterval([new Date(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)), new Date()])
                        }}>Последние 30 дней</button>
                        {/* </InputGroup> */}
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3 modal_info_body_tabs"
                    onSelect={(k) => setKey(k)}
                    activeKey={key}

                >
                    <Tab eventKey="crossroads" title="Перекрестки" className="tab">
                        <Table bordered striped>
                            <thead>
                                <tr key={0} style={{ textAlign: 'center' }}>
                                    <th rowSpan={2} style={{ verticalAlign: 'middle' }}>Комплекс</th>
                                    <th rowSpan={2} style={{ verticalAlign: 'middle' }}>Проездов</th>
                                    <th colSpan={5} >Нарушений</th>
                                </tr>
                                <tr key={1} style={{ textAlign: 'center' }}>
                                    {/* <th></th> */}
                                    <th >Всего</th>
                                    <th>Проезд на красный</th>
                                    <th>Стоп линия</th>
                                    <th>Выезд на встречную полосу</th>
                                    <th>Поворот не с того ряда</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    statisic.filter(host => host.type === 'crossroad').map(host => {
                                        return <tr key={host.name} style={{ textAlign: 'center' }}>
                                            <td style={{ verticalAlign: 'middle' }}>{host.name}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalFixations}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolations}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsWrongDir}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsStopLine}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsRedLight}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsLeftTurn}</td>
                                        </tr>
                                    })
                                }

                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ verticalAlign: 'middle' }}>Всего</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalFixations, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalViolations, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalViolationsWrongDir, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalViolationsStopLine, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalViolationsRedLight, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'crossroad').reduce((a, b) => a + b.totalViolationsLeftTurn, 0)}</th>
                                </tr>
                            </tbody>
                        </Table>


                    </Tab>

                    <Tab eventKey="speed" title="Скоростные" className="tab">
                        <Table bordered striped>
                            <thead>
                                <tr key={0} style={{ textAlign: 'center' }}>
                                    <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Комплекс</th>
                                    <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Проездов</th>
                                    <th colSpan={1} >Нарушений</th>
                                </tr>
                                {/* <tr style={{ textAlign: 'center' }}>
                                    <th >Всего</th>
                                    <th>Проезд на красный</th>
                                    <th>Стоп линия</th>
                                    <th>Выезд на встречную полосу</th>
                                    <th>Поворот не с того ряда</th>
                                </tr> */}
                            </thead>

                            <tbody>
                                {
                                    statisic.filter(host => host.type === 'speed').map(host => {
                                        return <tr key={host.name} style={{ textAlign: 'center' }}>
                                            <td style={{ verticalAlign: 'middle' }}>{host.name}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalFixations}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolations}</td>
                                            {/* <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsWrongDir}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsStopLine}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsRedLight}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsLeftTurn}</td> */}
                                        </tr>
                                    })
                                }

                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ verticalAlign: 'middle' }}>Всего</th>
                                    <th>{statisic.filter(host => host.type === 'speed').reduce((a, b) => a + b.totalFixations, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'speed').reduce((a, b) => a + b.totalViolations, 0)}</th>
                                    {/* <th>{violationsRedLight}</th>
                                    <th>{violationsStopLine}</th>
                                    <th>{violationsWrongDir}</th>
                                    <th>{violationsLeftTurn}</th> */}
                                </tr>
                            </tbody>
                        </Table>

                    </Tab>

                    <Tab eventKey="parking" title="Парковка" className="tab">
                        <Table bordered striped>
                            <thead>
                                <tr key={0} style={{ textAlign: 'center' }}>
                                    <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Комплекс</th>
                                    <th rowSpan={1} style={{ verticalAlign: 'middle' }}>Проездов</th>
                                    <th colSpan={1} >Нарушений</th>
                                </tr>
                                {/* <tr style={{ textAlign: 'center' }}>
                                    <th >Всего</th>
                                    <th>Проезд на красный</th>
                                    <th>Стоп линия</th>
                                    <th>Выезд на встречную полосу</th>
                                    <th>Поворот не с того ряда</th>
                                </tr> */}
                            </thead>

                            <tbody>
                                {
                                    statisic.filter(host => host.type === 'parking').map(host => {
                                        return <tr key={host.name} style={{ textAlign: 'center' }}>
                                            <td style={{ verticalAlign: 'middle' }}>{host.name}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalFixations}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolations}</td>
                                            {/* <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsWrongDir}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsStopLine}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsRedLight}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{host.totalViolationsLeftTurn}</td> */}
                                        </tr>
                                    })
                                }

                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ verticalAlign: 'middle' }}>Всего</th>
                                    <th>{statisic.filter(host => host.type === 'parking').reduce((a, b) => a + b.totalFixations, 0)}</th>
                                    <th>{statisic.filter(host => host.type === 'parking').reduce((a, b) => a + b.totalViolations, 0)}</th>
                                    {/* <th>{violationsRedLight}</th>
                                    <th>{violationsStopLine}</th>
                                    <th>{violationsWrongDir}</th>
                                    <th>{violationsLeftTurn}</th> */}
                                </tr>
                            </tbody>
                        </Table>


                    </Tab>


                </Tabs>




            </Modal.Body>

            <Modal.Footer style={{ display: 'inline-table', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div float='left' left='0' right='auto'>
                    <span>Всего проездов </span>
                    <Badge bg="success" style={{ marginRight: "3px" }}>{fixations}</Badge>
                    <span>/ нарушений </span>
                    <Badge bg="danger" style={{ marginLeft: "3px" }}>{violations}</Badge>
                    <Button style={{ float: 'right', left: 'auto', right: '0' }} size="sm" variant="primary" onClick={() => {

                        props.close()
                    }}>
                        Закрыть
                    </Button>
                    <Button style={{ float: 'right', left: 'auto', right: '0' }} size="sm" variant="warning" onClick={() => toExcel()}>Выгрузить</Button>

                    {/* <Button style={{ float: 'right', left: 'auto', right:'0' }} variant="success" onClick={console.log('toExcel')} size='sm'>Выгрузить XLSX</Button> */}
                </div>
                {/* &nbsp; */}

            </Modal.Footer>
        </Modal>
        // <div>
        // <div style={
        //     {
        //         display: 'flex',
        //         justifyContent: "space-between",
        //         marginTop: "10px",
        //     }}>
        //     <div style={{ width: '400px' }}>
        //         <InputGroup className="mb-3" style={{display: 'flex', justifyContent:'center'}}>
        //             <InputGroup.Text >Начало периода</InputGroup.Text>
        //             <DatePicker onChange={setStartDate} value={startDate} />

        //         </InputGroup>
        //     </div>

        //     <div style={{ width: '400px'}}>
        //         <InputGroup className="mb-3" style={{display: 'flex', justifyContent:'center'}}>
        //             <InputGroup.Text >Конец периода</InputGroup.Text>
        //             <DatePicker onChange={setEndDate} value={endDate} />

        //         </InputGroup>
        //     </div>


        // </div>

        //     <div >
        //         <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        //             <div>Проездов за период:</div>
        //             <div>{fixations}</div>
        //         </div>
        //         <div style={{ display: 'flex', justifyContent: 'space-between' }}  >
        //             <div>Нарушений за период:</div>
        //             <div>{violations}</div>

        //         </div>
        //     </div>
        // </div>
    )
}

export default ModalStat;