import './styles/App.css';
import "bootstrap/dist/css/bootstrap.min.css";
// import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import AppRouter from './components/AppRouter';
import { check } from './http/userApi';
import { useEffect, useState } from 'react';
import { getState } from './http/complexesApi';
import { CurrentComplexContext, UserContext, ComplexesContext, ComplexesFilterContext } from './context';
import { Riple } from 'react-loading-indicators'

const App = () => {
  const [userPayload, setUserPayload] = useState({})
  const [complexesPayload, setComplexesPayload] = useState({ hosts: {crossroad: [], speed: [], parking: [] }, vehicles: []})
  const [complexesFilterPayload, setComplexesFilterPayload] = useState({ severity: (localStorage.severity) ? localStorage.severity : 0})
  const [currentComplexPayload, setCurrentComplexPayload] = useState({})
  const [isLoading, setIsLoading] = useState(true);

  

  useEffect(() => {
    setIsLoading(true)
    check().then(data => {
      setUserPayload({ ...data, isAuth: true})
      setIsLoading(false)
    }).catch(() => {
      setUserPayload({ isAuth: false })
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (userPayload.isAuth) {
      getState().then(data => {
        setComplexesPayload(data)})
        .catch(e => { });
      const intervalId = setInterval(() => {
        getState().then(data => { setComplexesPayload(data); console.log(`Состояние обновлено ${new Date().toLocaleString("ru-RU", { timeZone: "Europe/Moscow" })}`) })
          .catch(e => { })
      }, 30000);    
      return () => clearInterval(intervalId); //This is important
    }
  }, [userPayload.isAuth]);  

  if(isLoading) {
    return <Riple color="green" size="medium" text="" textColor="" />
  }
  
 
  return (
    <div className="App">
      <UserContext.Provider value={{ userPayload, setUserPayload }}>
        <ComplexesContext.Provider value={{ complexesPayload, setComplexesPayload }}>
          <ComplexesFilterContext.Provider value={{ complexesFilterPayload, setComplexesFilterPayload }}>
            <CurrentComplexContext.Provider value={{ currentComplexPayload, setCurrentComplexPayload }}>
              <AppRouter />
            </CurrentComplexContext.Provider>
          </ComplexesFilterContext.Provider>
        </ComplexesContext.Provider>
      </UserContext.Provider>
    </div>
  );

}

export default App;
