import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Accordion, Card, Col, Container, Dropdown, Form, Row, SplitButton } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { getUsers, addUser, editUser, deleteUser, getRoles, addRole } from '../../http/userApi';

import "../../styles/ModalSearch.css"


const ModalUsers = props => {
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState(null)
    const [addUserClicked, setAddUserClicked] = useState(null)
    const [editUserClicked, setEditUserClicked] = useState(null)

    useEffect(() => {
        getUsers().then((res) => {
            setUsers(res.data)
        })
        getRoles().then((res) => {
            setRoles(res.data)
        })
        setRefresh(false)
    }, [refresh])


    // useEffect(() => {
    //     console.log(data)
    // }, [data])

    function del(user) {
        deleteUser(user).then((res) => {
            setRefresh(true)
            setAddUserClicked(null)
            setEditUserClicked(null)
        })
    }

    function add(user) {
        addUser(user).then((res) => {
            setRefresh(true)
            setAddUserClicked(null)
        })
    }

    function edit(user) {
        editUser(user).then((res) => {
            setRefresh(true)
            setEditUserClicked(null)
        })
    }

    return (
        <Modal show={props.visible}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onEscapeKeyDown={() => props.close()}
            scrollable
        >
            <Modal.Header style={{ justifyContent: 'center' }}>
                <Modal.Title >Пользователи</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        {/* <Col>ID</Col> */}
                        <Col>Логин</Col>
                        <Col>Имя</Col>
                        <Col>Роль</Col>
                        <Col>Комментарий</Col>
                        <Col></Col>
                    </Row>
                    <hr style={{ margin: '0' }} />
                    {
                        users.map((user) => {
                            return (

                                <Row key={user.id}>
                                    <Col>{user.login}</Col>
                                    <Col>{user.name}</Col>
                                    <Col>{user.roles.join(', ')}</Col>
                                    <Col>{user.comments}</Col>
                                    <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/* <span style={{ color: 'orange' }} className='fa fa-edit' onClick={() => {
                                            setData(user)
                                            setEditUserClicked(user);
                                            setAddUserClicked(null);
                                        }}></span> */}
                                        <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" nClick={() => {
                                            setData(user)
                                            setEditUserClicked(user);
                                            setAddUserClicked(null);
                                        }}>
                                            <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z"
                                                fill='orange'
                                            /></svg>
                                        {/* <span style={{ color: 'red' }} className='fa fa-trash' onClick={() => {
                                            // setData(user)
                                            del(user)
                                        }}></span> */}

                                        <svg className='modal_search_icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" onClick={() => {
                                            // setData(user)
                                            del(user)
                                        }}>
                                            <path d="M135.2 17.7L128 32 32 32C14.3 32 0 46.3 0 64S14.3 96 32 96l384 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-96 0-7.2-14.3C307.4 6.8 296.3 0 284.2 0L163.8 0c-12.1 0-23.2 6.8-28.6 17.7zM416 128L32 128 53.2 467c1.6 25.3 22.6 45 47.9 45l245.8 0c25.3 0 46.3-19.7 47.9-45L416 128z"
                                                fill='red'
                                            />
                                        </svg>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <hr style={{ margin: '5px' }} />

                    {addUserClicked ?

                        <Row>
                            <Col><Form.Control size="sm" type="text" placeholder="Логин" onChange={(e) => setData({ ...data, login: e.target.value })} /></Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Имя" onChange={(e) => setData({ ...data, name: e.target.value })} /></Col>
                            <Col>

                                <Form.Select onChange={(e) => setData({ ...data, roles: e.target.value.split(',') })}>
                                    {
                                        roles.map((role) => {
                                            return (
                                                <option value={role.name}>{role.name}</option>
                                            )
                                        })
                                    }


                                </Form.Select>
                            </Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Комментарий" onChange={(e) => setData({ ...data, comments: e.target.value })} /></Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Пароль" onChange={(e) => setData({ ...data, password: e.target.value })} /></Col>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} sm={1}>
                                <span style={{ color: 'green', padding: '3px' }} className='fa fa-check-circle' onClick={() => add(data)}></span>
                                <span style={{ color: 'red', padding: '3px' }} className='fa fa-ban' onClick={() => {
                                    setData(null)
                                    setAddUserClicked(null)
                                }}></span>
                            </Col>
                        </Row>
                        : null}

                    {editUserClicked ?
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{data.login}</Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Имя" defaultValue={data.name} onChange={(e) => setData({ ...data, name: e.target.value })} /></Col>
                            <Col>
                                <Form.Select value={data.roles[0]} onLoad={(e) => setData({ ...data, roles: e.target.value.split(',') })} onChange={(e) => setData({ ...data, roles: e.target.value.split(',') })}>
                                    {
                                        roles.map((role) => {
                                            return (
                                                <option value={role.name}>{role.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Комментарий" defaultValue={data.comments} onChange={(e) => setData({ ...data, comments: e.target.value })} /></Col>
                            <Col><Form.Control size="sm" type="text" placeholder="Пароль" onChange={(e) => setData({ ...data, password: e.target.value })} /></Col>
                            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} sm={1}>
                                <span style={{ color: 'green', padding: '3px' }} className='fa fa-check-circle' onClick={() => { edit(data) }}></span>
                                <span style={{ color: 'red', padding: '3px' }} className='fa fa-ban' onClick={() => {
                                    setData(null)
                                    setEditUserClicked(null)
                                }}></span>
                            </Col>
                        </Row>
                        : null}
                    {editUserClicked || addUserClicked ? <hr style={{ margin: '5px' }} /> : null}
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <Col sm={1}>
                            <span style={{ color: 'green', scale: '1.5' }} className='fa fa-plus-circle' onClick={() => {
                                setData({ roles: ['user'] })
                                setAddUserClicked({});
                                setEditUserClicked(false)
                            }}></span>
                        </Col>
                    </Row>
                </Container>

            </Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="primary" onClick={() => {
                    props.close()
                }}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalUsers
