import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ComplexData from './ComplexData';
import ComplexChilds from './ComplexChilds';
import '../../../styles/ModalContent.css';
import { useState } from 'react';
import ComplexImages from './ComplexImages';
import ComplexGraphs from './ComplexGraphs';
import ComplexStatistic from './ComplexStatistic';

const ModalContentCrossroad = props => {
    console.log(props)
    const [key, setKey] = useState('home');
    return (
        <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            className="mb-3 modal_info_body_tabs"
            onSelect={(k) => setKey(k)}
            activeKey={key}
            
        >
            <Tab eventKey="home" title="Состав перекрестка">
                <ComplexChilds childs={props.complex.child} />               
            </Tab>
            
            <Tab eventKey="data" title="Данные">
                <ComplexData complex={props.complex} />
            </Tab>

            <Tab eventKey="graphs" title="Графики">
                <ComplexGraphs complex={props.complex} />
            </Tab>
                
            <Tab eventKey="images" title="Кадры">
                <ComplexImages complex={props.complex} style={props.style}/>
            </Tab>

            <Tab eventKey="statistic" title="Статистика">
                <ComplexStatistic complex={props.complex} style={props.style}/>
            </Tab>
            {/* {
                ["codd", "uprdor", "camera_ovn" ].includes(props.complex.tags.find(tag => tag.tag === "type").value) ? 
                <Tab eventKey="stat" title="Статистика">
                    <ModalStat complex={props.complex}/>    
                </Tab>
                : null
            } */}

        </Tabs>
    )

}
export default ModalContentCrossroad