import { Card } from 'react-bootstrap';
import { itemMutator } from '../../../utils/converters';
import { problemColors } from '../../markers/helper';

const ComplexData = props => {
    var types_names = new Set()
    var types = {}
    props.complex.items.forEach(item => {
        var t
        t = item.name.indexOf('.') !== -1 ? item.name.split('.')[0] : t = 'Общие'
        types_names.add(t)
        types[t] = !types[t] ? [item] : [item, ...types[t]]
    })
    types_names = Array.from(types_names).sort()
    return (
        <div className="d-flex flex-wrap">
            {
                types_names.map(type => {
                    types[type].sort((a, b) => {
                        if (a.name.split('.')[1] > b.name.split('.')[1]) { return 1 }
                        if (a.name.split('.')[1] < b.name.split('.')[1]) { return -1 }
                        return 0

                    })
                    return (
                        <Card key={type} className='d-flex m-2 border'>
                            <Card.Header as="h6" className='d-flex justify-content-center p-0 bg-dark text-white'>{type}</Card.Header>
                            <Card.Body className='d-flex p-0'>
                                <div className='p-2'>
                                    {
                                        types[type].map(item => {
                                            const item_converted = itemMutator(item)
                                            const trigger = props.complex.triggers.find(tr => tr.items.find(it => it.itemid === item.itemid))
                                            let color = '#000'
                                            let fontWeight = ''
                                            let textDecoration = ''
                                            if (trigger) {
                                                // console.log(trigger)
                                                color = problemColors[trigger.priority]
                                                fontWeight = 'bold'
                                                textDecoration = 'underline'
                                            }
                                            if (item.status === "0") {
                                                return (
                                                    <div key={item.itemid} className='d-flex'>
                                                        {/* <span className='card-body' style={{ paddingRight: 5 }}><b>{item.name.indexOf('.') !== -1 ? item.name.split('.')[1] : item.name}:</b></span>
                                                        <span className='card-body' style={{ maxWidth: 250 }}>{item_converted.lastvalue} {item_converted.units}</span> */}

                                                        <span className='card-body' style={{ color: color, paddingRight: 5, textDecoration: textDecoration }}><b>{item.name.indexOf('.') !== -1 ? item.name.split('.')[1] : item.name}:</b></span>
                                                        <span className='card-body' style={{ color: color, maxWidth: 250 }}>{item_converted.lastvalue} {item_converted.units}</span>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default ComplexData