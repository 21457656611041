import { Popup } from "react-leaflet"
import { Button } from "react-bootstrap";
import { getAcknowldgedTriggerUrl, getBitrixNote, getNote, violationsFixations } from "../helper";
import React from "react"
import { getProblems } from "../helper";
import { useContext } from "react";
import { CurrentComplexContext, UserContext } from "../../../context";
import '../../../styles/MarkerPopup.css'
import '../../../styles/MarkerPopup_2x.css'
import DropdownLinks from "./DropdownLinks";
import DropdownScripts from "./DropdownScripts";
import DropdownBitrix from "./DropdownBitrix";
import { popup } from "leaflet";

const MarkerPopup = props => {
    // console.log(props.complex)
    const { violations, fixations } = violationsFixations(props.complex);
    const { setCurrentComplexPayload } = useContext(CurrentComplexContext);
    const { userPayload } = useContext(UserContext)
    const { roles } = userPayload



    const infoClick = () => {
        setCurrentComplexPayload({ modal: true, class: 'crossroad', complex: props.complex })
    }


    var problems = getProblems(props.complex)
    var notes = getNote(props.complex)
    var bitrixNotes = getBitrixNote(props.complex)
    var acknowledge_urls = []
    var acknowledge_url = getAcknowldgedTriggerUrl(props.complex)
    if (acknowledge_url) { acknowledge_urls.push({ sn: props.complex.name.split(' / ')[1], url: acknowledge_url }) }
    // console.log(acknowledge_urls)
    props.complex.child.map(host => {
        problems = [...getProblems(host), ...problems]
        notes = [...notes, ...getNote(host)]
        bitrixNotes = [...bitrixNotes, ...getBitrixNote(host)]
        acknowledge_url = getAcknowldgedTriggerUrl(host)
        if (acknowledge_url) { acknowledge_urls.push({ sn: host.name.split(' / ')[1], url: acknowledge_url }) }
        // const note = host.items.find(item => item.key_ === 'notes')?.lastvalue
        // if (note && note !== 'Нет данных') {
        //     notes.push([host.name.split(' / ')[1], note])
        // }
    })
    // console.log(notes)
    // problems = problems.sort((a,b) => {
    //     return a[1].split('/')[1].localeCompare(b[1].split('/')[1])
    // })

    // props.complex.vsc.map(vsc => {
    //     problems = [...getProblems(vsc), ...problems]
    // })
    // console.log(`fixations ${props.complex.name}`, fixations)
    return (
        <Popup maxWidth={550}>
            <div key={'popup_header'} className='popup_header'>
                <span style={{ fontWeight: 600, textAlign: 'center', width: '100%', maxWidth: '400px' }}>{props.complex.name} </span>
            </div>
            <div key={'popup_line_1'} style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap' }}>
                <div  key={'left'} style={{ display: "flex" }}>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233 7c-9.4-9.4-24.6-9.4-33.9 0l-96 96c-9.4 9.4-9.4 24.6 0 33.9l89.4 89.4-15.5 15.5C152.3 230.4 124.9 224 96 224c-31.7 0-61.5 7.7-87.8 21.2c-9 4.7-10.3 16.7-3.1 23.8L112.7 376.7 96.3 393.1c-2.6-.7-5.4-1.1-8.3-1.1c-17.7 0-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32c0-2.9-.4-5.6-1.1-8.3l16.4-16.4L242.9 506.9c7.2 7.2 19.2 5.9 23.8-3.1C280.3 477.5 288 447.7 288 416c0-28.9-6.4-56.3-17.8-80.9l15.5-15.5L375 409c9.4 9.4 24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9l-89.4-89.4 55-55c12.5-12.5 12.5-32.8 0-45.3l-48-48c-12.5-12.5-32.8-12.5-45.3 0l-55 55L233 7zm159 351l-72.4-72.4 62.1-62.1L454.1 296 392 358.1zM226.3 192.4L153.9 120 216 57.9l72.4 72.4-62.1 62.1z"
                            fill="navy"
                        />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_body_badge popup_body_badge_coordinates" onClick={() => window.open(`https://yandex.ru/maps/?whatshere[point]=${props.complex.inventory.location_lon},${props.complex.inventory.location_lat}&whatshere[zoom]=17`)}>{props.complex.inventory.location_lat}, {props.complex.inventory.location_lon}</span>
                </div>
                <div key={'right'} style={{ display: "flex" }}>
                    <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M171.3 96L224 96l0 96-112.7 0 30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192l0-96 81.2 0c9.7 0 18.9 4.4 25 12l67.2 84L272 192zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36L171.3 32c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256L0 368c0 17.7 14.3 32 32 32l33.3 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l130.7 0c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80l33.3 0c17.7 0 32-14.3 32-32l0-48c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"
                            fill="green"
                        />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                </div>
            </div>

            <div key={'popup_line_2'} style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row', marginTop: '2px', marginBottom: '2px', whiteSpace: 'nowrap' }}>
                <div  key={'left'} style={{ display: "flex" }}>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">
                        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
                            fill="brown"
                        />
                        </svg>
                    </span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_body_badge popup_body_badge_date">{(new Date()).toLocaleString('ru')}</span>
                </div>
                <div key={'right'} style={{ display: "flex" }}>
                    <span className="popup_body_badge popup_body_badge_violations">{violations}</span>
                    <hr className="popup_hr" style={{ width: '5px' }} />
                    <span className="popup_hr_text">

                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" version="1.1" viewBox="0 0 700 700">
                            <title>Нарушений</title>
                            <circle fill="#fff" cx="350" cy="350" r="350" />
                            <circle fill="none" stroke="#000" strokeWidth={2} cx="350" cy="350" r="349" />
                            <circle fill="#ff0000" cx="350" cy="350" r="330" />
                            <rect fill="#fff" transform="scale(1.12903226) translate(-20 -20)" x="116" y="270" width="428" height="120" rx="8.86" ry="8.86" />
                        </svg>
                    </span>

                    {/* <span className="popup_hr_line" style={{ paddingBottom: '2px' }}>-</span> */}
                    <hr className="popup_hr" style={{ width: '5px' }} />
                </div>

            </div>
            {/* <hr style={{ margin: '5px' }} />
            <div className='popup_body'>
                <span className="popup_body_badge popup_body_badge_fixations">Проездов</span>
                <span className="popup_body_badge popup_body_badge_fixations">{fixations}</span>
                <span className="popup_body_badge popup_body_badge_violations">{violations}</span> 
                <span className="popup_body_badge popup_body_badge_violations">Нарушений</span>
                <br />
            </div> */}

            {
                notes.length === 0 && bitrixNotes.length === 0 ? null :
                    <>
                        <div key={'popup_line_3'} style={{ display: "flex", flexDirection: 'row' }}>
                            <hr className="popup_hr" style={{ width: '100%' }} />
                            <span className="popup_hr_text">
                                <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c0 0 0 0 0 0s0 0 0 0s0 0 0 0c0 0 0 0 0 0l.3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"
                                        fill="purple"
                                    />
                                </svg>
                            </span>
                            <hr className="popup_hr" style={{ width: '5px' }} />
                        </div>
                        {notes.map(note => {
                            return (<div key={note} className='popup_body'>
                                <span className="popup_body_badge popup_body_badge_alerts_host" style={{ color: 'red', fontStyle: 'italic' }}><b>Zabbix</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_host" style={{ whiteSpace: 'nowrap' }}><b>{note[0]}</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: 'purple' }}>{note[1]}</span>
                            </div>)
                        })}
                        {bitrixNotes.map(note => {
                            return (<div key={note} className='popup_body'>
                                <span className="popup_body_badge popup_body_badge_alerts_host" style={{ color: 'blue', fontStyle: 'italic' }}><b>Bitrix</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_host" style={{ whiteSpace: 'nowrap' }}><b>{note[0]}</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: 'purple' }}>{note[1]}</span>
                            </div>)
                        })}
                    </>
            }
            <div key={'popup_line_4'} style={{ display: "flex", flexDirection: 'row' }}>
                <hr className="popup_hr" style={{ width: '100%' }} />
                <span className="popup_hr_text">
                    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"
                            fill="#fd7e14"
                        />
                    </svg>
                </span>
                <hr className="popup_hr" style={{ width: '5px' }} />
            </div>
            {
                problems.length === 0
                    ?
                    <div key={'popup_problems'} className='popup_body'>
                        <span className="popup_body_badge popup_body_badge_alerts_good">Работа в штатном режиме</span>
                    </div>
                    : problems.map(element => {
                        return (
                            <div className='popup_body' key={element[0]}>
                                <span className="popup_body_badge popup_body_badge_alerts_host"><b>{element[1].split('/')[1]}</b></span>
                                <span className="popup_body_badge popup_body_badge_alerts_problem" style={{ color: element[4] }}>{element[2]}: {element[3]}</span>
                            </div>

                        )
                    })
            }
            <hr style={{ width: '100%', margin: '6px' }} />

            <div key={'popup_footer'} className='popup_footer'>
                {roles && !(roles.includes('operator')) ?
                    <div style={{ display: 'flex' }}>
                        <DropdownLinks complex={props.complex} />
                        <DropdownScripts complex={props.complex} notify={props.notify} />
                        {/* <DropdownBitrix urls={acknowledge_urls}/> */}
                    </div>
                    : null
                }
                <div style={{ display: 'flex' }}>
                    {roles && !(roles.includes('operator')) ?
                        // <div style={{ display: 'flex'}}>
                        <DropdownBitrix urls={acknowledge_urls} />
                        // <Button size="sm" variant="outline-success" href={acknowledge_url} title="Ссылка на внеплановую задачу в Битрикс"> <i style={{ paddingRight: 3}} class="fa fa-screwdriver-wrench"></i> </Button>
                        // </div>
                        : null
                    }
                    {props.complex.bitrix.regular_task ?
                        <Button size="sm" variant="outline-success" onClick={infoClick} title="Сcылка на регламентную задачу в Битрикс"> <i style={{ paddingRight: 2, width: '25px' }} class="fa fa-broom"></i> </Button>
                        : null
                    }

                    <Button size="sm" variant="outline-primary" onClick={infoClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 7px' }}>
                        {/* <svg className='popup_svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                            <path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"
                                fill="black" />
                        </svg> */}

                        <svg className='popup_svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 18a1 1 0 1 0 2 0a1 1 0 0 0-2 0m-6 0a1 1 0 1 0 2 0a1 1 0 0 0-2 0m-6 0a1 1 0 1 0 2 0a1 1 0 0 0-2 0m12-6a1 1 0 1 0 2 0a1 1 0 0 0-2 0m-6 0a1 1 0 1 0 2 0a1 1 0 0 0-2 0m-6 0a1 1 0 1 0 2 0a1 1 0 0 0-2 0m12-6a1 1 0 1 0 2 0a1 1 0 0 0-2 0m-6 0a1 1 0 1 0 2 0a1 1 0 0 0-2 0M5 6a1 1 0 1 0 2 0a1 1 0 0 0-2 0"
                        /></svg>
                        {/* <svg width="28px" height="15px" viewBox="0 3 48 48">
                        <circle fill="#2196F3" cx="24" cy="24" r="21"/>
                        <rect x="22" y="22" fill="#ffffff" width="4" height="11"/>
                        <circle fill="#ffffff" cx="24" cy="16.5" r="2.5"/>
                    </svg> */}
                    </Button>
                </div>
            </div>
        </Popup>
    )
}


export default MarkerPopup;
