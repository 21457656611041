import L from "leaflet";
import "leaflet-extra-markers";
// import "font-awesome/css/font-awesome.css";
// import "leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css";
// import { config } from '@fortawesome/fontawesome-svg-core'
// import '@fortawesome/fontawesome-free/css/all.min.css';
import { uptimeToString } from '../../utils/converters'
import '../../styles/Icon.css'

export const problemColors = {
    "0": '#541FB3',
    "1": '#28a1d7',
    "2": '#f5bb3a',
    "3": '#ef9227',
    "4": '#d63a23',
    "5": '#a23337'
}


// config.autoAddCss = false
// отображение батареи в зависимости от напряжения
export function accDiv(complex) {
    const powerMacro = complex.macros.find(item => item.macro === '{$POWER}')
    if (powerMacro && powerMacro.value === 'BAT') {
        const voltage = complex.items.find(item => item.key_ === 'mssVoltage' || item.key_ === 'voltage_board' || item.key_ === "pwr")
        if (voltage) {
            const accVoltage = voltage.lastvalue
            let level = 4
            if (accVoltage < 12) level = 3
            if (accVoltage < 11.7) level = 2
            if (accVoltage < 11.3) level = 1
            return `<div className="bat-${level}"></div>`
        }
    }
    return ''
}


export function getTooltip(complex) {
    //показ значка батареи в зависимости от уровня заряда
    if (complex.macros.find(macro => macro.macro === '{$POWER}')) {
        return `
            <style>
                .leaflet-tooltip {
                    position: absolute;
                    background-color: rgba(0, 0, 0, 0.1) !important;
                    border: 0px solid #000;
                    color: #000;
                    padding: 0px;
                }
                .bat-4 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-4.png');
                    background-size: 40px;
                }
                .bat-3 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-3.png');
                    background-size: 40px;
                }
                .bat-2 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-2.png');
                    background-size: 40px;
                }
                .bat-1 {
                    width: 40px;
                    height: 14px;
                    background: url('/img/battery/bat-1.png');
                    background-size: 40px;
                }
                </style>
                ${accDiv(complex)}
        `
        // показ значка солнца, если питание автономное
        // if(complex.macros.find(macro => macro.macro === '{$POWER}').value === 'BAT'){
        //     return `
        //         <style>
        //             .leaflet-tooltip {
        //                 position: absolute;
        //                 background-color: rgba(0, 0, 0, 0.1) !important;
        //                 border: 0px solid #000;
        //                 color: #000;
        //                 padding: 0px;
        //             }
        //             .sun-tooltip {
        //                 width: 20px;
        //                 height: 20px;
        //                 background: url('/img/sun2.png');
        //                 background-size: 20px;
        //             }
        //         </style>
        //         <div class='sun-tooltip'>
        //         </div>
        //     `
        // }
    }

    return ''
}


export function violationsFixations(complex) {
    if (complex.items.find(item => item.description === 'fixations')) {
        var tmp = complex.items.filter(item => item.description === "fixations")
        // console.log(tmp.sort((a, b) => a.name.localeCompare(b.name)))
        const fixations = tmp.length > 0 ? tmp.sort((a, b) => a.name.localeCompare(b.name)).map(t => t.lastvalue).join('/') : 0;
        tmp = complex.items.filter(item => item.description === "violations")
        const violations = tmp.length > 0 ? tmp.sort((a, b) => a.name.localeCompare(b.name)).map(t => t.lastvalue).join('/') : 0;
        return { violations, fixations };
    }
    return [0, 0]
}
export function violationsFixationsCODD(complex) {

    if (complex.items.find(item => item.key_ === 'fixations')) {
        const fixations = complex.items.filter(item => item.key_ === "fixations").length > 0 ? complex.items.filter(item => item.key_ === "fixations")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "violations").length > 0 ? complex.items.filter(item => item.key_ === "violations")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]')) {
        const fixations = complex.items.filter(item => item.key_ === "total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]").length > 0 ? complex.items.filter(item => item.key_ === "total-fixations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "total-violations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]").length > 0 ? complex.items.filter(item => item.key_ === "total-violations-krechet-mysql.sh[{HOST.IP}, {$MYSQL_LOGIN}, {$MYSQL_PASSWORD}]")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'mssFixation')) {
        const fixations = complex.items.filter(item => item.key_ === "mssFixation").length > 0 ? complex.items.filter(item => item.key_ === "mssFixation")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "mssViolation").length > 0 ? complex.items.filter(item => item.key_ === "mssViolation")[0].lastvalue : 0;
        return { violations, fixations };
    }


    if (complex.items.find(item => item.key_ === 'todays_fixations')) {
        const fixations = complex.items.filter(item => item.key_ === "todays_fixations").length > 0 ? complex.items.filter(item => item.key_ === "todays_fixations")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "todays_violations").length > 0 ? complex.items.filter(item => item.key_ === "todays_violations")[0].lastvalue : 0;
        return { violations, fixations };
    }

    if (complex.items.find(item => item.key_ === 'count_cars')) {
        const fixations = complex.items.filter(item => item.key_ === "count_cars").length > 0 ? complex.items.filter(item => item.key_ === "count_cars")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "todays_violation").length > 0 ? complex.items.filter(item => item.key_ === "todays_violation")[0].lastvalue : 0;
        return { violations, fixations };
    }

    if (complex.items.find(item => item.key_ === 'mssFixation')) {
        const fixations = complex.items.filter(item => item.key_ === "mssFixation").length > 0 ? complex.items.filter(item => item.key_ === "mssFixation")[0].lastvalue : 0;
        const violations = complex.items.filter(item => item.key_ === "mssViolation").length > 0 ? complex.items.filter(item => item.key_ === "mssViolation")[0].lastvalue : 0;
        return { violations, fixations };
    }

    const fixations = complex.items.filter(item => item.key_ === "Ptolemey.todays_passages").length > 0 ? complex.items.filter(item => item.key_ === "Ptolemey.todays_passages")[0].lastvalue : 0;
    const violations = complex.items.filter(item => item.key_ === "Ptolemey.todays_violations").length > 0 ? complex.items.filter(item => item.key_ === "Ptolemey.todays_violations")[0].lastvalue : 0;


    return { violations, fixations };
}

export function getProblems(complex) {
    const result = []
    // console.log(complex)
    for (let trigger of complex.triggers) {
        let key = trigger.triggerid

        let problemName = trigger.description;
        let problemDuration = Math.round((new Date()).getTime() / 1000) - trigger.lastchange;
        // let problemNote = ''
        if (trigger.problem.tags.filter(tag => tag.tag === 'name')[0]) {
            problemName = trigger.problem.tags.filter(tag => tag.tag === 'name')[0].value
            if (problemName.includes('нет фиксаций')) {
                let item = complex.items.find(item => item.key_ === 'last_fixation')
                const tag_item = trigger.problem.tags.find(tag => tag.tag === 'item')?.value
                
                if(tag_item) {
                    // console.log(complex)
                    item = complex.items.find(item => item.key_ === tag_item)
                } 
                problemDuration = parseInt(item.lastvalue)
            } else {
                problemDuration = Math.floor(Date.now() / 1000) - parseInt(trigger.lastchange) + parseInt(trigger.problem.tags.filter(tag => tag.tag === 'base')[0].value);
            }
            // if (problemName.includes('нет связи') && complex.items.find(item => item.key_ === 'notes' && item.lastvalue !== '')) {
            //     problemNote = ` (${complex.items.find(item => item.key_ === 'notes')?.lastvalue})`
            // }
        }

        let priority = parseInt(trigger.priority, 10)
        let problemColor = '#009549';
        switch (priority) {
            case 0: problemColor = '#541FB3'; break;
            case 1: problemColor = '#28a1d7'; break;
            case 2: problemColor = '#f5bb3a'; break;
            case 3: problemColor = '#ef9227'; break;
            case 4: problemColor = '#d63a23'; break;
            case 5: problemColor = '#a23337'; break;
            default: problemColor = '#009549'; break;
        }
        if (priority !== 0) result.push([key, complex.name, problemName, uptimeToString(problemDuration), problemColor])
    }
    return result.sort((a, b) => {
        return a[2].localeCompare(b[2])
    })
}


export function getTriggerMaxPriority(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    if (complex.child) {
        complex.child.map(child => {
            priorities = [...priorities, ...child.triggers.map(trigger => parseInt(trigger.priority, 10))]
        })
    }
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(maxPriority)
    return maxPriority
}

export function getIcon(complex, maxPriority) {
    // console.log(maxPriority)
    // let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    // if (complex.child) {
    //     complex.child.map(child => {
    //         priorities = [...priorities, ...child.triggers.map(trigger => parseInt(trigger.priority, 10))]
    //     })
    // }
    // let maxPriority = Math.max(...priorities);
    // if (!isFinite(maxPriority)) maxPriority = 0;
    // const maxPriority = getTriggerMaxPriority(complex)
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default: markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }
    // console.log(complex)


    let acknowledged = false
    let extra = ''
    let many_violations = false
    complex.triggers.map(trigger => {
        if (parseInt(trigger.problem.acknowledged) === 1) {
            acknowledged = true
        }
        if (trigger.problem.tags.find(tag => tag.tag === '220_down')) {
            extra = 'no_power'
        } else if (trigger.problem.tags.find(tag => tag.tag === 'power_off')) {
            extra = 'power_off'
        } else {
            let disabled = trigger.problem.tags.find(tag => tag.tag === 'disabled')
            if (disabled) {
                switch (disabled.value) {
                    case 'all':
                        extra = 'directions_disabled_all'
                        break;
                    default:
                        extra = 'directions_disabled'
                }
            }
        }
        if (trigger.problem.tags.find(tag => tag.tag === 'many_violations')) {
            many_violations = true
        }



    })

    if (complex.child) {
        // console.log(complex.childs)
        complex.child.map(child => {
            child.triggers.map(trigger => {
                if (parseInt(trigger.problem.acknowledged) === 1) {
                    acknowledged = true
                }
            })
        })
    }


    // markerColor = 'violet';
    // иконка с количеством нарушений
    const { violations } = violationsFixations(complex);

    const cclass = complex.tags.find(tag => tag.tag === 'class')
    // console.log(cclass)
    var shape = 'circle'
    switch (cclass.value) {
        case 'crossroad':
            shape = 'square'
            break;
        case 'parking':
            shape = "penta"
            break;
        default:
            shape = 'circle'
    }

    // console.log(extra)
    // many_violations = true
    // extra = 'directions_disabled_all'

    var innerIconRight = ''
    var innerIconLeft = ''
    var innerCenter = ''
    var innerIconCenterBottom = ''

    if (acknowledged) {
        // console.log('acknowledged')
        // console.log(complex)
        innerIconRight = `
            <div class='marker-inner-icon-right'>
                <i style="scale:0.9;position: relative;margin-left:0.4px;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-cog fa-spin" aria-hidden="true"></i>
            </div>`
    }

    switch (extra) {
        case 'no_power':
            innerIconLeft = `
                <div class='marker-inner-icon-left'>
                    <i style="scale:0.8;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-plug" aria-hidden="true"></i>
                </div>`
            break;
        case 'power_off':
            innerIconLeft = `
                    <div class='marker-inner-icon-left'>
                        <i style="scale:0.9;position: relative;margin-top:0px;padding:0;color: brown;" class="fa fa-xs fa-power-off" aria-hidden="true"></i>
                    </div>`
            break;
        case 'directions_disabled_all':
            innerIconLeft = `
                <div class='marker-inner-icon-left' >
                
                    <i style="scale:0.9;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-arrows" aria-hidden="true"></i>
                </div>`
            break;
        case 'directions_disabled':
            innerIconLeft = `
                <div class='marker-inner-icon-left'>
                    <i style="scale:0.9;position: relative;margin-top:0;padding:0;color: brown;" class="fa fa-xs fa-arrows-h" aria-hidden="true"></i>

                </div>`
            break;

    }

    innerCenter = `<div class="marker-inner-center-text">${violations}</div>`
    if (complex.regular_task) {
        innerCenter = `<div class="marker-inner-center-circle"><div class="marker-inner-center-circle-text">${violations}</div></div>`
    }
    if (many_violations) {
        innerCenter = `
            <div class="marker-inner-center-icon">

                <i class="fa fa-xs fa-arrow-up fa-beat-fade"  style="--fa-beat-fade-scale: 1.4; padding-right: 3px" aria-hidden="true"></i>
            </div>`
        markerColor = 'purple'
    }
    var innerIcon = `${innerIconLeft}${innerIconRight}${innerIconCenterBottom}`
    var innerHTML = `
        <div class='marker-inner'>
            ${innerCenter}
            ${innerIcon}
        </div>`


    let iconMarker = L.ExtraMarkers.icon({
        markerColor,
        shape: shape,
        prefix: 'fa',
        innerHTML: innerHTML
    });


    return iconMarker;
}

export function getIconDeactivated() {
    let markerColor = 'black'
    let iconMarker = L.ExtraMarkers.icon({
        icon: 'fa-close',
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        // extraClasses: "fa-power-off",
    });
    return iconMarker;
}

function calculatePercentage(array) {
    const totalCount = array.length;
    const counts = array.reduce((acc, num) => {
        acc[num] = (acc[num] || 0) + 1;
        return acc;
    }, {});

    const percentages = {};
    for (const [key, value] of Object.entries(counts)) {
        percentages[key] = (value / totalCount) * 100;
    }

    return percentages;
}

function generateConicGradient(percentages) {
    // console.log(percentages)
    const colorMap = {
        0: '#008C3A',
        1: '#21A2DA',
        2: '#F4B62A',
        3: '#F28A18',
        4: '#D32D19',
        5: '#9C262A',
    }
    let gradientParts = [];
    let cumulativePercentage = 0;

    for (const [num, percent] of Object.entries(percentages)) {
        gradientParts.push(`${colorMap[num]} ${cumulativePercentage}% ${cumulativePercentage + percent}%`);
        cumulativePercentage += percent;
    }

    return `conic-gradient(${gradientParts.join(', ')})`;
}


export function getIconMarkerCluster(mcount, priorities) {
    // let markerColor = '#008C3A';
    // let gradient = ''
    // if (priorities.length > 0) {
    //     console.log(priorities)
    const percentages = calculatePercentage(priorities)
    const gradient = generateConicGradient(percentages);
    // }

    const icon = L.divIcon({
        className: 'marker-cluster-icon',
        html: `<div style='background:${gradient};' class='marker-cluster-div'></div>
        <i class='marker-cluster-text'>${mcount}</i>`,
        iconSize: [26, 26],
        iconAnchor: [13, 26]
    });
    return icon

}

export function getIconVehicle(vehicle) {
    // console.log('getIconVehicle')
    // console.log(vehicle)
    let course = vehicle.items.find(item => item.key_.includes('course'))?.lastvalue
    const speed = vehicle.items.find(item => item.key_.includes('speed'))?.lastvalue
    const time = vehicle.items.find(item => item.key_.includes('time'))?.lastvalue
    // course = '90'
    // const speed = '66'
    let markerColor = '#000000'
    let border_radius = '50% 50% 50% 50%;'


    if (speed) {
        if (parseInt(speed) > 0) {
            markerColor = 'rgb(15, 138, 15);'
            border_radius = '0% 50% 50% 50%;'
        }

        if (parseInt(speed) === 0) {
            markerColor = 'rgb(15, 71, 138);'
            // markerColor = '#fffff'
        }
    }
    if (course) {
        course = (parseInt(course) + 45).toString()
    }
    if (time) {
        if (new Date() - new Date(parseInt(time) * 1000) > 10800 * 1000) {
            markerColor = '#9C262A'
        }
    }
    //<i class='fa fa-3x fa-van-shuttle' style='top: 50%; padding-top: 0px; margin-left: -7px; position: absolute; scale:0.43; z-index: 1000;'></i>
    const icon = L.divIcon({
        className: 'vehicle-div-icon',
        html: `
        <i class='vehicle-text' style='color:${markerColor}; border: 1px solid ${markerColor};'>${vehicle.name.split(' / ')[1]}</i>
        <div style='background-color:${markerColor}; transform: rotate(${course}deg); border-radius: ${border_radius};' class='vehicle-div'></div>
        <div style='position:absolute; bottom: 0; display:flex; margin-left: 9px; margin-bottom: -9px; z-index: 1000'>
            <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" >
            <path d="M64 104l0 88 96 0 0-96L72 96c-4.4 0-8 3.6-8 8zm482 88L465.1 96 384 96l0 96 162 0zm-226 0l0-96-96 0 0 96 96 0zM592 384l-16 0c0 53-43 96-96 96s-96-43-96-96l-128 0c0 53-43 96-96 96s-96-43-96-96l-16 0c-26.5 0-48-21.5-48-48L0 104C0 64.2 32.2 32 72 32l120 0 160 0 113.1 0c18.9 0 36.8 8.3 49 22.8L625 186.5c9.7 11.5 15 26.1 15 41.2L640 336c0 26.5-21.5 48-48 48zm-64 0a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM160 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"
                fill="white"
            />
            </svg>
        </div>
        </div>
        `,
        iconSize: [34, 34],
        iconAnchor: [17, 23],

    });
    return icon
}
export function getIconBattery(bp) {
    let icon = { 'classname': 'fa fa-solid fa-rotate-270 fa-battery-full', 'color': '#009549' }
    if (bp < 80) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-three-quarters'
        icon.color = '#009549'
    }
    if (bp < 60) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-half'
        icon.color = '#ef9227'
        // icon.color = '#f5bb3a'
    }
    if (bp < 40) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-quarter'
        icon.color = '#ef9227'
    }
    if (bp < 20) {
        icon.classname = 'fa fa-solid fa-rotate-270 fa-battery-empty'
        icon.color = '#d63a23'
    }


    return <div style={{ color: icon.color, opacity: '1', scale: '1.4 1.1', padding: '5px 2px 5px' }} className={icon.classname}></div>
}

export function getIconBatteryNoData() {
    let icon = { 'classname': 'fa fa-solid fa-rotate-270 fa-battery-empty', 'color': '#d63a23' }
    let iconx = { 'classname': 'fa fa-times', 'color': '#d63a23' }
    return <div style={{ color: icon.color, opacity: '1', scale: '1.4 1.1', padding: '5px 2px 5px', position: "relative" }} className={icon.classname}>
        <div style={{ color: iconx.color, opacity: '1', scale: '1.3 1', padding: '4px 2px 4px 3px', position: "absolute", top: 0, left: 0 }} className={iconx.classname}>

        </div>
    </div>
}

export function getPowerOff() {
    return <div style={{ color: 'black', opacity: '1', scale: '1.4 1.4', padding: '5px 5px 1px 4px' }} className='fa fa-power-off'></div>
}

export function getPowerPlug() {
    return <div style={{ color: 'brown', opacity: '1', scale: '1.1 1.1', padding: '5px 5px 1px 4px' }} className='fa fa-solid fa-plug'></div>
}

export function getLogo() {
    // let icon = { 'classname': 'fa fa-solid fa-code', 'color': 'green' }

    // return <span style={{ color: icon.color, opacity: '1', scale: '1.4 1.4', padding: '5px' }} className={icon.classname}></span>
    return <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path d="M9.4 86.6C-3.1 74.1-3.1 53.9 9.4 41.4s32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 9.4 86.6zM256 416l288 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-288 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
            fill='green'
        /></svg>

}

export function getIconOVN(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default: markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }


    let iconMarker = L.ExtraMarkers.icon({
        // number : violations,
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        icon: 'fa-camera',
        // innerHTML: `<div style="padding-top:4px">
        //                 <b><div style="color:white;font-size:0.7rem;margin-top:5px">${violations}</div></b>
        //            <div>`


    });

    return iconMarker;
}




export function getIconPP(complex) {
    let priorities = complex.triggers.map(trigger => parseInt(trigger.priority, 10));
    let maxPriority = Math.max(...priorities);
    if (!isFinite(maxPriority)) maxPriority = 0;
    // console.log(`maxPriority : ${maxPriority}`);
    let markerColor = 'green';
    switch (maxPriority) {

        case 0: markerColor = 'green'; break;
        case 1: markerColor = 'cyan'; break;
        case 2: markerColor = 'yellow'; break;
        case 3: markerColor = 'orange'; break;
        case 4: markerColor = 'orange-dark'; break;
        case 5: markerColor = 'red'; break;
        default: markerColor = 'green'; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }


    let iconMarker = L.ExtraMarkers.icon({
        // number : violations,
        markerColor,
        shape: 'circle',
        prefix: 'fa',
        icon: 'fa-navicon',
        // innerHTML: `<div style="padding-top:4px">
        //                 <b><div style="color:white;font-size:0.7rem;margin-top:5px">${violations}</div></b>
        //            <div>`


    });


    return iconMarker;
}


export function getAcknowldgedTriggerUrl(complex) {
    const trigger = complex.triggers.find(trigger => trigger.problem.acknowledged === '1')
    if (trigger) {
        const acknowledge = trigger.problem.acknowledges.find(ack => ack.message.includes('https'))
        if (acknowledge) {
            return acknowledge.message
        }
    }

}

export function getNote(complex) {
    const note = complex.items.find(item => item.key_ === 'notes')?.lastvalue
    if (note && note !== 'Нет данных') {
        return [[complex.name.split(' / ')[1], note]]
    }


    return []
}

export function getBitrixNote(complex) {
    if (complex.bitrix && complex.bitrix.comment) {
        return [[complex.name.split(' / ')[1], complex.bitrix.comment]]
    }
    return []
}







export function getSVGIcon(complex, maxPriority) {
    var svgIcon = ''
    const colorMap = {
        0: '#008C3A',
        1: '#21A2DA',
        2: '#F4B62A',
        3: '#F28A18',
        4: '#D32D19',
        5: '#9C262A',
    }

    let markerColor = colorMap[0];
    switch (maxPriority) {

        case 0: markerColor = colorMap[0]; break;
        case 1: markerColor = colorMap[1]; break;
        case 2: markerColor = colorMap[2]; break;
        case 3: markerColor = colorMap[3]; break;
        case 4: markerColor = colorMap[4]; break;
        case 5: markerColor = colorMap[5]; break;
        default: markerColor = colorMap[0]; break;
    }
    if (complex.status === "1") {
        markerColor = 'black'
    }


    let acknowledged = false
    let extra = ''
    let many_violations = false
    // console.log(complex)

    //Если тип перекресток ищем заметки для добавления выключенных направлений
    if (complex.tags.find(tag => tag.value === 'crossroad')) {
        let disabled = complex.items.find(item => item.key_ === 'notes')?.lastvalue
        if (disabled) {
            if (disabled.toLowerCase().includes('вcе')) {
                extra = 'directions_disabled_all'
            } else {
                extra = 'directions_disabled'
            }
        }
    }

    complex.triggers.map(trigger => {
        if (parseInt(trigger.problem.acknowledged) === 1) {
            acknowledged = true
        }
        if (trigger.problem.tags.find(tag => tag.tag === '220_down')) {
            extra = 'no_power'
        } else if (trigger.problem.tags.find(tag => tag.tag === 'power_off')) {
            // console.log(complex)
            extra = 'power_off'
            if (trigger.problem.acknowledges.find(ack => ack.message === 'automation')) {
                extra = 'power_off_auto'
            }

        }
        if (trigger.problem.tags.find(tag => tag.tag === 'many_violations')) {
            many_violations = true
        }



    })

    if (complex.child) {
        complex.child.map(child => {
            child.triggers.map(trigger => {
                if (parseInt(trigger.problem.acknowledged) === 1) {
                    acknowledged = true
                }
            })
        })
    }

    const { violations } = violationsFixations(complex);
    if (many_violations) {
        markerColor = 'purple'
    }
    const cclass = complex.tags.find(tag => tag.tag === 'class')

    const bp = complex.items.find(item => item.key_ === 'bp')
    const nodata = complex.triggers.find(trigger => trigger.problem.tags.find(tag => tag.tag === 'acc_nodata'))
    switch (cclass.value) {
        case 'crossroad':
            svgIcon += `
            <div style='position:absolute; bottom: 0; display:flex; margin-left: 3px'>
            <svg width="33" height="44" viewBox="0 0 35 45" xmlns="http://www.w3.org/2000/svg">
            <defs>
                        <filter id="shadow" x="0" y="0" width="200%" height="200%">
                            <feDropShadow dx="2" dy="2" stdDeviation="1" flood-color="#000000" flood-opacity=".8" />
                            
                        </filter>
                    </defs>
                <path filter="url(#shadow)" d="M28.205 3.217H6.777c-2.367 0-4.286 1.87-4.286 4.179v19.847c0 2.308 1.919 4.179 4.286 4.179h5.357l5.337 13.58 5.377-13.58h5.357c2.366 0 4.285-1.87 4.285-4.179V7.396c0-2.308-1.919-4.179-4.285-4.179" fill="${markerColor}" />
                <g opacity=".15" transform="matrix(1.0714 0 0 -1.0714 -233.22 146.783)">
                    <path d="M244 134h-20c-2.209 0-4-1.746-4-3.9v-18.525c0-2.154 1.791-3.9 4-3.9h5L233.982 95 239 107.675h5c2.209 0 4 1.746 4 3.9V130.1c0 2.154-1.791 3.9-4 3.9m0-1c1.654 0 3-1.301 3-2.9v-18.525c0-1.599-1.346-2.9-3-2.9h-5.68l-.25-.632-4.084-10.318-4.055 10.316-.249.634H224c-1.654 0-3 1.301-3 2.9V130.1c0 1.599 1.346 2.9 3 2.9h20" fill="#231f20" />
                </g>
            </svg>
            </div>`
            break;
        case 'parking':
            svgIcon += `
            <div style='position:absolute; bottom: 0; display:flex; margin-left: 3px'>
            <svg width="33" height="44" viewBox="0 0 35 45" xmlns="http://www.w3.org/2000/svg">
            <defs>
                        <filter id="shadow" x="0" y="0" width="200%" height="200%">
                            <feDropShadow dx="2" dy="2" stdDeviation="1" flood-color="#000000" flood-opacity=".8" />
                            
                        </filter>
                    </defs>
                <path filter="url(#shadow)" d="M1.872 17.35L9.679 2.993h15.615L33.1 17.35 17.486 44.992z" fill="${markerColor}" />
                <g opacity=".15" transform="matrix(1.0769 0 0 -1.0769 -272.731 48.23)">
                    <path d="M276.75 42h-14.5L255 28.668 269.5 3 284 28.668zm-.595-1l6.701-12.323L269.5 5.033l-13.356 23.644L262.845 41z" fill="#231f20" />
                </g>
            </svg>
            </div>`
            break;
        case 'vgk':
                svgIcon += `
                <div style='position:absolute; bottom: 0; display:flex; margin-left: 3px'>
                <svg width="34" height="44" viewBox="0 0 35 45" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                            <filter id="shadow" x="0" y="0" width="200%" height="200%">
                                <feDropShadow dx="2" dy="2" stdDeviation="1" flood-color="#000000" flood-opacity=".8" />
                                
                            </filter>
                        </defs>
                    <path d="M32.92 16.93l-3.525-3.525V8.419a1.983 1.983 0 00-1.983-1.982h-4.985L18.9 2.91a1.984 1.984 0 00-2.803 0l-3.524 3.526H7.588a1.983 1.983 0 00-1.982 1.982v4.986L2.081 16.93a1.982 1.982 0 000 2.803l3.525 3.526v4.984c0 1.096.888 1.983 1.982 1.983h4.986L17.457 45l4.97-14.773h4.985a1.983 1.983 0 001.983-1.983V23.26l3.525-3.526a1.982 1.982 0 000-2.803" 
                        fill="${markerColor}" />
                    <g opacity=".15" transform="matrix(1.0667 0 0 -1.0667 -347.3 97.26)">
                        <path d="M342 89c-.476 0-.951-.181-1.314-.544l-3.305-3.305h-4.673a1.858 1.858 0 01-1.859-1.858v-4.674l-3.305-3.305a1.857 1.857 0 010-2.627l3.305-3.305v-4.674a1.86 1.86 0 011.859-1.859h4.673L341.959 49l4.659 13.849h4.674a1.86 1.86 0 011.859 1.859v4.674l3.305 3.305a1.858 1.858 0 010 2.627l-3.305 3.305v4.674a1.859 1.859 0 01-1.859 1.858h-4.674l-3.304 3.305A1.851 1.851 0 01342 89m0-1a.853.853 0 00.607-.251l3.304-3.305.293-.293h5.088a.86.86 0 00.859-.858v-5.088l3.598-3.598A.852.852 0 00356 74a.85.85 0 00-.251-.606l-3.598-3.598v-5.088a.86.86 0 00-.859-.859h-5.393l-.229-.681-3.702-11.006-3.637 11.001-.227.686h-5.396a.86.86 0 00-.859.859v5.088l-3.598 3.598c-.162.162-.251.377-.251.606s.089.445.251.607l3.598 3.598v5.088a.86.86 0 00.859.858h5.087l3.598 3.598A.853.853 0 00342 88" 
                            fill="#231f20" 
                        />
                    </g>
                </svg>'
                </div>`
                break;
        default:
            svgIcon += `
            <div style='position:absolute; bottom: 0; display:flex; margin-left: 3px'>
                <svg width="32" height="40" viewBox="0 0 35 45" xmlns="http://www.w3.org/2000/svg">

                    <defs>
                        <filter id="shadow" x="0" y="0" width="200%" height="200%">
                            <feDropShadow dx="2" dy="2" stdDeviation="1" flood-color="#000000" flood-opacity=".8" />
                            
                        </filter>
                    </defs>

                    <path d="M17.5 2.746c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.439-17.863.056-.08a15.422 15.422 0 002.343-6.112c.123-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307" 
                        fill="${markerColor}" 
                        filter="url(#shadow)"
                    />
                    <path d="M17.488 2.748c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.44-17.863.055-.08a15.422 15.422 0 002.343-6.112c.124-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307m0 1.071c7.68 0 13.929 6.386 13.929 14.236 0 .685-.064 1.423-.193 2.258-.325 2.075-1.059 3.99-2.164 5.667l-.055.078-11.557 16.595L6.032 26.14l-.12-.174a14.256 14.256 0 01-2.105-5.29 14.698 14.698 0 01-.247-2.62c0-7.851 6.249-14.237 13.928-14.237" 
                        fill="#231f20" 
                        opacity=".15"
                    />
                </svg> 
            </div>`
    }

    if (bp) {
        // console.log(bp.toString())
        // var color = '#009549'
        var color = colorMap[0]
        var textColor = 'white'
        var h = 100
        if (bp.lastvalue <= 80) {
            // color = '#009549'
            color = colorMap[1]
            textColor = 'black'
            h = 80
        }
        if (bp.lastvalue <= 60) {
            // color = '#21A2DA'
            color = colorMap[2]
            textColor = 'black'
            h = 60
            // icon.color = '#f5bb3a'
        }
        if (bp.lastvalue <= 40) {
            // color = '#ef9227'
            color = colorMap[3]
            textColor = 'black'
            h = 40
        }
        if (bp.lastvalue <= 20) {
            // color = '#d63a23'
            color = colorMap[4]
            textColor = 'white'
            h = 20
        }
        if (nodata) {
            color = "#0800ff"
            textColor = 'white'
        }

        //Квадратный заполнитель
        // var content = `
        //         <path d="m 3 ${(100 - h + 6).toString()}
        //                 l 100 0 
        //                 a 3 3 0 0 1 3 3 
        //                 l 0 ${h.toString()}
        //                 a 3 3 0 0 1 -3 3 
        //                 l -100 0
        //                 a 3 3 0 0 1 -3 -3
        //                 l 0 -${h.toString()}
        //                 a 3 3 0 0 1 3 -3
        //                 "
        //                 fill="${color}"
        //                 filter="url(#shadow)"
        //             />
        //         <text x="54" y="64" font-family="Arial" font-size="80" fill="black" text-anchor="middle" alignment-baseline="middle" stroke="black" stroke-width="3" >
        //         ${bp.lastvalue.toString()}

        //         </text>
        // `
        var content = `
            <div style='position:absolute; bottom: 0; left: 0; display: flex;margin-bottom: 0px;' title='Заряд АКБ'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="24 -24 512 512">
                    <text x="290" y="256" font-size="300" fill="${textColor}" textLength="${bp.lastvalue === "100" ? 430 : 330}" lengthAdjust="spacing" text-anchor="middle" alignment-baseline="middle" font-style="bold" >
                    ${bp.lastvalue}
                    </text>
                </svg>
            </div>`

        //Стрелки в квадратную иконку акб
        // if (nodata) {
        //     content = `  
        //     <line x1="10" y1="16" x2="96" y2="102" stroke="#d63a23" stroke-width="15"/>
        //     <line x1="96" y1="16" x2="10" y2="102" stroke="#d63a23" stroke-width="15"/>
        //     `
        // }

        // if (nodata) {
        //     h = 0
        //     color = 'white'
        //     content = `  
        //     <div style='position:absolute; bottom: 0; left: 0; display: flex;margin-bottom: 2px; margin-left: 1px' title='Отсутствуют данные о заряде АКБ'>
        //         <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512">
        //         <line x1="100" y1="100" x2="412" y2="412" stroke="#d63a23" stroke-width="75"/>
        //         <line x1="412" y1="100" x2="100" y2="412" stroke="#d63a23" stroke-width="75"/>
        //         </svg>
        //     </div>
        //     `
        // }

        const ba = complex.items.find(item => item.key_ === 'ba')?.lastvalue
        const la = complex.items.find(item => item.key_ === 'la')?.lastvalue
        // console.log(ba, la)
        if (ba && la && (ba - la) > 2) {
            content += `
            <div style='position:absolute; bottom: 0; left: 0; display: flex;margin-bottom: -2px; margin-left: 11px' title='Идет заряд АКБ'>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 448 512">
                    <path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288l111.5 0L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7l-111.5 0L349.4 44.6z"
                        fill="white"
                        stroke="black"
                        stroke-width="45"
                    >
                </svg>
            </div>`
        }


        //Квадратная иконка акб
        // svgIcon += `
        //     <div style='position:absolute; display:flex; margin-left:0px; margin-top: 30px'>
        //         <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-8 0 140 140">

        //                 <defs>
        //                 <filter id="shadow1" x="0" y="0" width="200%" height="200%">
        //                     <feDropShadow dx="15" dy="15" stdDeviation="1" flood-color="#000000" flood-opacity=".3" />

        //                 </filter>
        //             </defs>
        //             <path d="m 3 3
        //                 l 100 0 
        //                 a 6 6 0 0 1 6 6 
        //                 l 0 100
        //                 a 6 6 0 0 1 -6 6 
        //                 l -100 0
        //                 a 6 6 0 0 1 -6 -6
        //                 l 0 -100
        //                 a 6 6 0 0 1 6 -6
        //                 "
        //                 fill="white"
        //                 stroke="black"
        //                 stroke-width="8" 
        //                 filter="url(#shadow1)"
        //             />
        //             ${content}


        //         </svg>
        //     </div>`

        svgIcon += `
        <div style='position:absolute; bottom: 0; left: 0; display: flex; margin-bottom: 0px;'>
            <svg  class="shadow1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512">
                <circle cx="256" cy="256" r="236" fill="${color}" stroke="black" stroke-width="0" fill-opacity="1"/>
            </svg>
        </div>
        ${content}
        `


    }

    var extra_svg = ''
    if (acknowledged) {
        extra_svg += `
            <div style='position:absolute; top: 0; right: 0; display: flex; margin-top: 0px;margin-right:2px' >
                <svg  class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                    <circle cx="256" cy="256" r="236" fill="white" stroke="black" stroke-width="0"/>
                </svg>
            </div>`
        if (complex.bitrix.repair) {
            extra_svg += `
                <div style='position:absolute; top: 0; right: 0; display: flex; margin-top: 3px; margin-right:5px' title='На ремонте'>
                    <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path xmlns="http://www.w3.org/2000/svg" d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7L336 192c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                            fill="${colorMap[5]}" 
                        />
                    </svg>
                </div>`
        } else {
            extra_svg += `
                <div style='position:absolute; top: 0; right: 0; display: flex; margin-top: 2px; margin-right:4px' title='Проблема подтверждена'>
                    <svg  xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 512 512">
                        <path  d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"
                            fill="${colorMap[5]}" 
                    />
                    </svg>
                </div>`
            if (complex.child && complex.child.find(child => child.bitrix.repair)) {
                // console.log(complex)
                extra_svg += `
                
                    <div style='position:absolute; top: 0; right: 0; display: flex;margin-top: -2px; margin-right: 7px' title='Кто то на ремонте)'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 552 552">
                            <path xmlns="http://www.w3.org/2000/svg" d="M352 320c88.4 0 160-71.6 160-160c0-15.3-2.2-30.1-6.2-44.2c-3.1-10.8-16.4-13.2-24.3-5.3l-76.8 76.8c-3 3-7.1 4.7-11.3 4.7L336 192c-8.8 0-16-7.2-16-16l0-57.4c0-4.2 1.7-8.3 4.7-11.3l76.8-76.8c7.9-7.9 5.4-21.2-5.3-24.3C382.1 2.2 367.3 0 352 0C263.6 0 192 71.6 192 160c0 19.1 3.4 37.5 9.5 54.5L19.9 396.1C7.2 408.8 0 426.1 0 444.1C0 481.6 30.4 512 67.9 512c18 0 35.3-7.2 48-19.9L297.5 310.5c17 6.2 35.4 9.5 54.5 9.5zM80 408a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"
                                fill="white"
                                
                                stroke="black"
                                stroke-width="40" 
                            >
                        </svg>
                    </div>`
            }



        }

    }

    switch (extra) {
        case 'no_power':
            extra_svg += `
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 1px' >
                    <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                        <circle cx="256" cy="256" r="236" fill="white" stroke="black" stroke-width="0"/>
                    </svg>
                </div>
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 4px; margin-left:3px' title='Электропитание отсутствует'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12"  viewBox="0 0 384 512">
                        <path d="M96 0C78.3 0 64 14.3 64 32l0 96 64 0 0-96c0-17.7-14.3-32-32-32zM288 0c-17.7 0-32 14.3-32 32l0 96 64 0 0-96c0-17.7-14.3-32-32-32zM32 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l0 32c0 77.4 55 142 128 156.8l0 67.2c0 17.7 14.3 32 32 32s32-14.3 32-32l0-67.2C297 398 352 333.4 352 256l0-32c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 160z"
                            fill="${colorMap[5]}"
                            
                        />
                    </svg>
                </div>`
            break;
        case 'power_off':
        case 'power_off_auto':
            extra_svg += `
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 0px'>
                    <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                        <circle cx="256" cy="256" r="236" fill="${extra === 'power_off' ? 'white' : colorMap[5]} " stroke="black" stroke-width="0"/>
                    </svg>
                </div>
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 2px; margin-left:2px' 
                    title="${extra === 'power_off' ? 'Выключено вручную' : 'Выключено автоматически из-за низкого заряда АКБ'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-24 -24 530 530">
                        <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 224c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"
                            fill="${extra === 'power_off' ? colorMap[5] : 'white'}"
                            stroke="${extra === 'power_off' ? colorMap[5] : 'white'}"
                            stroke-width="18"
                        />
                    </svg>
                </div>`
            // if(extra === 'power_off_auto') {
            //     extra_svg += `
            //         <div style='position:absolute; top: 0; left: 0; display: flex; margin-left: 4px;'>
            //             <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512">
            //                 <text x="290" y="256" font-family="Arial" font-size="320" fill="white" text-anchor="middle" alignment-baseline="middle" font-style="bold" stroke='black' stroke-width='15'>
            //                 M
            //                 </text>
            //             </svg>
            //         </div>`
            // }
            break;
        case 'directions_disabled_all':
            extra_svg += `
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 0px'>
                    <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                        <circle cx="256" cy="256" r="236" fill="white" stroke="black" stroke-width="0"/>
                    </svg>
                </div>
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 2px; margin-left:2px' title='Все направления выключены'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 512 512">
                        <path xmlns="http://www.w3.org/2000/svg" d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l9.4-9.4L224 224l-114.7 0 9.4-9.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-9.4-9.4L224 288l0 114.7-9.4-9.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-9.4 9.4L288 288l114.7 0-9.4 9.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l9.4 9.4L288 224l0-114.7 9.4 9.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-64-64z"
                        fill="${colorMap[5]}"
                        /></svg>
                
                </div>`
            break;
        case 'directions_disabled':
            extra_svg += `
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 0px'>
                    <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                        <circle cx="256" cy="256" r="236" fill="white" stroke="black" stroke-width="0"/>
                    </svg>
                </div>
                <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 2px; margin-left:2px' title='Выключено хотя бы одно направление'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 512 512">
                        <path xmlns="http://www.w3.org/2000/svg" d="M406.6 374.6l96-96c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224l-293.5 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288l293.5 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"
                        fill="${colorMap[5]}"
                        /></svg>
                </div>`
            break;

    }
    // extra_svg += `
    //     <div style='position:absolute; display: flex; margin-top: 13px; margin-left: 7px'>
    //     <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width=24" height="24" viewBox="0 0 24 24">
    //     <text x="12" y="12" font-family="Consolas" font-size="14" fill="white" text-anchor="middle" alignment-baseline="middle" filter="url(#shadow)">
    //         ${violations}
    //     </text>
    //     </svg>
    //     </div>`
    if (many_violations) {
        // extra_svg += `
        // <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 15px; margin-left: 12px;'>
        //     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="-192 56 576 512">
        //     <g >
        //     <path transform="translate(-132 0)" d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2 160 448c0 17.7 14.3 32 32 32s32-14.3 32-32l0-306.7L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
        //         fill="white"
        //     />


        //     <animateTransform 
        //         attributeName="transform" 
        //         type="scale" 
        //         values="1; 1.5; 1.8; 1;" 
        //         dur="1s" 
        //         repeatCount="indefinite">      
        //     </animateTransform>
        //     </g>
        //     </svg>
        // </div>`
        // <i class="fa fa-xs fa-arrow-up fa-beat-fade"  style="--fa-beat-fade-scale: 1.4; padding-right: 3px; color: white" aria-hidden="true"></i>
        extra_svg += `
            <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 13px; margin-left: 13px; z-index: 400;'>
                
                <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="-312 -312 896 896">
                <g transform="translate(352 352)">
                    <path transform="translate(-176 -176)" d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM151.2 217.4c-4.6 4.2-7.2 10.1-7.2 16.4c0 12.3 10 22.3 22.3 22.3l41.7 0 0 96c0 17.7 14.3 32 32 32l32 0c17.7 0 32-14.3 32-32l0-96 41.7 0c12.3 0 22.3-10 22.3-22.3c0-6.2-2.6-12.1-7.2-16.4l-91-84c-3.8-3.5-8.7-5.4-13.9-5.4s-10.1 1.9-13.9 5.4l-91 84z"
                        fill="white"
                    />
                    <animateTransform
                        attributeName="transform"
                        type="scale"
                        dur="2s"
                        values="1; 1.8; 1.8; 1;"
                        repeatCount="indefinite">
                    </animateTransform>
                </g>
                </svg>
            </div>`
    // } else if (cclass.value === 'vgk') {
    //     extra_svg += `
    //         <div style='position:absolute; top: 0; left: 0; display: flex; margin-top: 11px; margin-left: 11px; z-index: 400;'>
    //             <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    //             <path xmlns="http://www.w3.org/2000/svg" d="M522.1 62.4c16.8-5.6 25.8-23.7 20.2-40.5S518.6-3.9 501.9 1.6l-113 37.7C375 15.8 349.3 0 320 0c-44.2 0-80 35.8-80 80c0 3 .2 5.9 .5 8.8L117.9 129.6c-16.8 5.6-25.8 23.7-20.2 40.5s23.7 25.8 40.5 20.2l135.5-45.2c4.5 3.2 9.3 5.9 14.4 8.2L288 480c0 17.7 14.3 32 32 32l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-160 0 0-294.7c21-9.2 37.2-27 44.2-49l125.9-42zM439.6 288L512 163.8 584.4 288l-144.9 0zM512 384c62.9 0 115.2-34 126-78.9c2.6-11-1-22.3-6.7-32.1L536.1 109.8c-5-8.6-14.2-13.8-24.1-13.8s-19.1 5.3-24.1 13.8L392.7 273.1c-5.7 9.8-9.3 21.1-6.7 32.1C396.8 350 449.1 384 512 384zM129.2 291.8L201.6 416 56.7 416l72.4-124.2zM3.2 433.1C14 478 66.3 512 129.2 512s115.2-34 126-78.9c2.6-11-1-22.3-6.7-32.1L153.2 237.8c-5-8.6-14.2-13.8-24.1-13.8s-19.1 5.3-24.1 13.8L9.9 401.1c-5.7 9.8-9.3 21.1-6.7 32.1z"
    //                 fill="white"
    //             />
    //             </svg>
    //         </div>`
    } else {
        extra_svg += `
            <div style='
                font-size: 12px; 
                position:absolute; 
                display: flex; 
                margin-top: ${cclass.value !== 'speed' ? 12 : 14}px; 
                padding-right: 1px; 
                justify-content: center; 
                z-index: 300;
                color:white;
                width: 100%;
                font-weight: bold;;
            '>${cclass.value === 'vgk' ? violations.split('/')[0] : violations}</div>`
    }
    // innerCenter = `<div class="marker-inner-center-text">${violations}</div>`
    if (complex.bitrix.regular_task) {
        extra_svg += `
            
                <div style='position:absolute; bottom: 0; right: 0; display: flex; margin-bottom: 1px; margin-right:1px'>
                    <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 512 512">
                        <circle cx="256" cy="256" r="236" fill="white" stroke="black" stroke-width="0"/>
                    </svg>
                </div>
                <div style='position:absolute; bottom: 0; right: 0; display: flex; margin-bottom: 2px; margin-right:3px'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 -1.43 122.88 122.88">
                        <path xmlns="http://www.w3.org/2000/svg" d="M110.97,1.27L70.02,42.73l10.67,10.36l41.25-41.56C125.58,3.7,117.92-2.85,110.97,1.27L110.97,1.27z M54.04,46.81c0.4-0.31,0.81-0.58,1.22-0.81l0.15-0.08c2.35-1.28,4.81-1.24,7.39,0.53l7.17,6.98l0.11,0.11l6.6,6.42 c2.73,2.78,3.34,5.88,1.83,9.31l-19.35,50.75C24.02,112.99-0.34,87.94,0,49.73C19.23,55.35,37.75,57.19,54.04,46.81L54.04,46.81z"
                        fill="${colorMap[5]}"
                        /></svg>
                </div>
        `
    }

    // var innerIcon = `${innerIconLeft}${innerIconRight}${innerIconCenterBottom}`
    // var innerHTML = `
    //     <div class='marker-inner'>
    //         ${innerCenter}
    //         ${innerIcon}
    //     </div>`




    // const svgIcon = `
    // <div style='position:absolute; bottom: 0; display:flex; margin-left: 3px'>
    // <svg width="32" height="40" viewBox="0 0 35 45" xmlns="http://www.w3.org/2000/svg">
    //     <svg width="32" height="44" viewBox="0 0 32 45" xmlns="http://www.w3.org/2000/svg">
    //         <path d="M17.5 2.746c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.439-17.863.056-.08a15.422 15.422 0 002.343-6.112c.123-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307" 
    //             fill="${markerColor}" 
    //         />
    //         <path d="M17.488 2.748c-8.284 0-15 6.853-15 15.307 0 .963.098 1.902.265 2.816a15.413 15.413 0 002.262 5.684l.134.193 12.295 17.785 12.44-17.863.055-.08a15.422 15.422 0 002.343-6.112c.124-.791.206-1.597.206-2.423 0-8.454-6.716-15.307-15-15.307m0 1.071c7.68 0 13.929 6.386 13.929 14.236 0 .685-.064 1.423-.193 2.258-.325 2.075-1.059 3.99-2.164 5.667l-.055.078-11.557 16.595L6.032 26.14l-.12-.174a14.256 14.256 0 01-2.105-5.29 14.698 14.698 0 01-.247-2.62c0-7.851 6.249-14.237 13.928-14.237" 
    //             fill="#231f20" opacity=".15" 
    //         />
    //     </svg> 
    // </div>`
    // const svgIcon = `
    // <div style='position:absolute; bottom: 0; display:flex'>
    //     <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38" viewBox="-24 -6 384 532">
    //         <defs>
    //             <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
    //             <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
    //             <feOffset in="blur" dx="5" dy="5" result="offsetBlur" />
    //             <feMerge>
    //                 <feMergeNode in="offsetBlur" />
    //                 <feMergeNode in="SourceGraphic" />
    //             </feMerge>
    //             </filter>
    //         </defs>
    //         <path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z" 
    //                 fill="${markerColor}" 
    //                 stroke="#000" 
    //                 stroke-width="5" 
    //                 filter="url(#shadow)" 
    //         />
    //     </svg>
    // </div>`
    // ;


    const iconMarker = new L.DivIcon({
        html: svgIcon + extra_svg,
        className: '', // чтобы убрать стандартные стили
        iconSize: [40, 46], // Размер иконки
        popupAnchor: [0, -32],
        iconAnchor: [20, 46] // Координаты якоря (центр нижнего края)
    });



    return iconMarker;
}


export function getSVGIconVehicle(vehicle) {
    // console.log('getIconVehicle')
    // console.log(vehicle)
    var svgIcon = ''
    let course = vehicle.items.find(item => item.key_.includes('course'))?.lastvalue
    const speed = vehicle.items.find(item => item.key_.includes('speed'))?.lastvalue
    const time = vehicle.items.find(item => item.key_.includes('time'))?.lastvalue
    // course = '90'
    // const speed = '66'
    let markerColor = '#000000'
    let border_radius = '50% 50% 50% 50%;'


    if (speed) {
        if (parseInt(speed) > 0) {
            markerColor = '#0f8a0f'
            // markerColor = '#fff'
            border_radius = '0% 50% 50% 50%;'
        }

        if (parseInt(speed) === 0) {
            // markerColor = 'rgb(15, 71, 138);'
            markerColor = '#0f478a'
        }
    }
    if (course) {
        course = (parseInt(course) + 45).toString()
    }
    if (time) {
        if (new Date() - new Date(parseInt(time) * 1000) > 10800 * 1000) {
            markerColor = '#9C262A'
        }
    }

    {/* <i class='vehicle-text' style='color:${markerColor}; border: 1px solid ${markerColor};'>${vehicle.name.split(' / ')[1]}</i>
        <div style='background-color:${markerColor}; transform: rotate(${course}deg); border-radius: ${border_radius};' class='vehicle-div'></div>
        
        <i class='fa fa-3x fa-van-shuttle' style='top: 50%; padding-top: 0px; margin-left: -6px; position: absolute; scale:0.4; z-index: 1000;'></i>
        </div> */}

    svgIcon += `
        <div style='position:absolute; top: 0; display:flex; margin-top: -30px; margin-left: -10px'>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 180 36">
                    <path d="m 13 0
                        l 150 0 
                        a 10 10 0 0 1 10 10 
                        l 0 30
                        a 10 10 0 0 1 -10 10 
                        l -150 0
                        a 10 10 0 0 1 -10 -10
                        l 0 -30
                        a 10 10 0 0 1 10 -10
                        "
                        fill="white"
                        stroke="black"
                        stroke-width="6" 
                        filter="url(#shadow1)"
                    />
                </svg>
           
        </div>
        <div style='position:absolute; top: 0; display: flex;margin-top: -30px;'>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 512 512">
                    <text x="290" y="256" font-size="360" fill="${markerColor}" text-anchor="middle" alignment-baseline="middle" font-style="bold">
                    ${vehicle.name.split(' / ')[1]}
                    </text>
                </svg>
        </div>
        <div style='position:absolute; bottom: 0; display:flex;'>
                <svg class="shadow" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                <defs>
                    <filter id="shadow" x="0" y="0" width="200%" height="200%">
                        <feDropShadow dx="2" dy="2" stdDeviation="1" flood-color="#000000" flood-opacity=".8" />
                        
                    </filter>
                </defs>

                        <circle cx="256" cy="256" r="256" 
                        stroke="black" stroke-width="0"
                        fill="${markerColor}" 
                    />
                </svg>
           
        </div>`


    const icon = L.divIcon({
        className: '',
        html: svgIcon,
        iconSize: [34, 34],
        iconAnchor: [17, 34],
        popupAnchor: [0, -34]

    });
    return icon
}







